import React, { useEffect, useState } from "react";
//components
import { Container } from "../../../../components/container/container";
import { Body } from "../../../../components/container/Body";
import { TitlePage } from "../../../../components/titlePages/title.page";
import { Title } from "../../../../components/titlePages/title";
import { formattTimeToShow } from "../../../../services/api/callAPIsFunctions/defaultCalls.api";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../../contexts/useAuth";
import { DefaultLoader } from "../../../../components/loaders/defaultLoader";
import { useThemeContext } from "../../../../contexts/themeContext";
import { currencyPtBr } from "../../../../utils/formatCurrency/currencyTextPTBR";
import { FaList, FaRegWindowClose } from "react-icons/fa";
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import { ScreenModal } from "../../../../components/modals/notification/screenModal";
import { Button } from "../../../../components/buttons/button.default";
import { LabelInput } from '../../../../components/label/label.input';
import { responseError } from "../../../../utils/responsesFunctions/error.response";
import { Footer } from "../../../../components/footer/Footer";
import CurrencyInput from "../../../../components/input/inputCurrencyPtBr";
import { useApiContext } from "../../../../contexts/ApiInterceptorContext";

export function ActivitiesActionsEdit() {

    const { defaultCallsSchedules, api } = useApiContext()
    const { userData } = useAuth()
    const { showUniversalModal, setShowUniversalModal, setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [typeModal, setTypeModal] = useState('')
    const subscriptionsId = useParams().id?.replace(':', '')
    const [subscriptionData, setSubscriptionData] = useState(JSON.parse(localStorage.getItem('@activitiesSubscriptions')))
    const [planData, setPlanData] = useState('')
    const [cancellationFee, setCancellationFee] = useState(0)
    const [chargeBack, setChargeBack] = useState(0)
    const [cancellationFeeError, setCancellationFeeError] = useState(false)

    async function getData() {

        const subscriptionDataApi = await defaultCallsSchedules.getActivitiesSubscriptionsById(userData[0]?.token, subscriptionsId.replace(':', ''))
        setSubscriptionData(subscriptionDataApi.data.map(subscription => {
            return {
                ...subscription,
                created_at: formattTimeToShow(subscription.created_at),
                original_startDate: subscription?.startDate,
                startDate: formattTimeToShow(subscription?.startDate),
                original_finishDate: subscription?.finishDate,
                finishDate: formattTimeToShow(subscription?.finishDate),
                original_planValue: subscription?.planValue,
                planValue: currencyPtBr(subscription.planValue),
                original_status: subscription?.status,
                payments_method: subscription?.paymentMethod === "P" ? "Pix" : subscription?.paymentMethod === "CE" ? "Cartão de crédito" : '-',
                status: subscription?.status === 0 ? <div className='p-2 '><a className='bg-blue-400 p-1 rounded-lg text-white'>Aguardando pagamento</a></div> :
                    subscription?.status === 1 && subscription?.active === false ? <div className='p-2 '><a className='bg-green-300 p-1 rounded-lg text-white'>Pago | Inativo</a></div> :
                        subscription?.status === 1 && subscription?.active ? <div className='p-2 '><a className='bg-green-500 p-1 rounded-lg text-white'>Pago | Realizado</a></div> :
                            subscription?.status === 2 && <div className='p-2 '><a className='bg-gray-500 p-1 rounded-lg text-white'>Cancelado</a></div>

            }
        })[0])
        getPlansData(subscriptionDataApi.data[0]?.activities_plans_id)
    }

    async function getPlansData(id) {
        const plansData = await defaultCallsSchedules.getActivitiesPlansById(userData[0].token, id ? id : subscriptionData?.activities_plans_id)
        if (plansData.error) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText(plansData.data)
        }

        setPlanData(plansData.data[0])
        setLoading(false)
    }

    useEffect(() => {
        
        setShowUniversalModal(false)
        if (!subscriptionData) {
            getData()
        } else {
            getPlansData()
        }
        localStorage.removeItem('@activitiesSubscriptions')
    }, [])

    async function handleCancelSubscription() {
        if (cancellationFee > subscriptionData.original_planValue) {
            return setCancellationFeeError(true)
        }
        setLoading(true)
        try {
            await api.put(`/api/v1/activitiesSubscriptions/cancel/subscriptions_id/${subscriptionData?.id}`, {
                fee: cancellationFee,
                chargeBack
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            const subscriptionCancelled = await defaultCallsSchedules.getActivitiesSubscriptionsById(userData[0]?.token, subscriptionsId)
            setShowNotificationModal(true)
            setShowNotificationModalSuccess(true)
            setShowNotificationModalText('Inscrição atualizada com sucesso!')
            setSubscriptionData(subscriptionCancelled.data[0])
            setShowUniversalModal(false)
            setLoading(false)
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    useEffect(() => {
        if (!showUniversalModal) {
            setTypeModal('')
        }
    }, [showUniversalModal])

    return (
        <>
            <ScreenModal>
                {
                    typeModal === 'cancelConfirm' ?
                        <div className="gap-4 flex flex-col w-full p-4 items-start justify-center">
                            {
                                subscriptionData?.original_status === 1 ?
                                    <p className="text-titleBlackTextLight dark:text-titleGrayTextDark text-sm">Deseja realmente cancelar esta inscricão?</p>
                                    :
                                    <p className="text-titleBlackTextLight dark:text-titleGrayTextDark text-sm">Deseja realmente reativar esta inscricão?</p>

                            }
                            <div className="flex w-full items-center justify-center gap-4">
                                <Button onClick={() => setTypeModal('cancelData')} shadow={true}>Sim</Button>
                                <Button onClick={() => setShowUniversalModal(false)} shadow={false} approval={false}>Não</Button>
                            </div>
                        </div>
                        :
                        typeModal === "termsInReceiptModal" ?
                            <div className="gap-4 flex flex-col w-full p-4 items-start justify-center">
                                <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Exibir termos de uso em recibo?</p>
                                <div className='flex gap-4 items-center justify-center'>
                                    <Button 
                                        approval={true}
                                        onClick={() => {
                                            localStorage.setItem('receiptActivitiesActions', JSON.stringify({
                                                id: subscriptionData?.id,
                                                client: subscriptionData?.client,
                                                original_startDate: subscriptionData.original_startDate,
                                                original_finishDate: subscriptionData.original_finishDate,
                                                created_at: subscriptionData.created_at,
                                                planName: planData?.planName,
                                                original_planValue: subscriptionData?.original_planValue,
                                                chargeBack: subscriptionData?.chargeBack,
                                                totalValue: subscriptionData?.totalValue,
                                                numberOfInstallments: subscriptionData?.numberOfInstallments,
                                                payments_method: subscriptionData?.payments_method,
                                                active: subscriptionData?.active,
                                                activities_id: planData?.activities_id,
                                                showTerms: true
                                            }));
                                            window.open('/#/receiptActivitiesActions', "_blank");
                                            setTypeModal('');
                                            setShowUniversalModal(false);
                                        }}
                                        shadow={true}
                                    >
                                        Exibir
                                    </Button>
                                    <Button 
                                        approval={false} 
                                        onClick={() => {
                                            localStorage.setItem('receiptActivitiesActions', JSON.stringify({
                                                id: subscriptionData?.id,
                                                client: subscriptionData?.client,
                                                original_startDate: subscriptionData.original_startDate,
                                                original_finishDate: subscriptionData.original_finishDate,
                                                created_at: subscriptionData.created_at,
                                                planName: planData?.planName,
                                                original_planValue: subscriptionData?.original_planValue,
                                                chargeBack: subscriptionData?.chargeBack,
                                                totalValue: subscriptionData?.totalValue,
                                                numberOfInstallments: subscriptionData?.numberOfInstallments,
                                                payments_method: subscriptionData?.payments_method,
                                                active: subscriptionData?.active,
                                                activities_id: planData?.activities_id,
                                                showTerms: false
                                            }));
                                            window.open('/#/receiptActivitiesActions', "_blank");
                                            setTypeModal('');
                                            setShowUniversalModal(false);
                                        }}
                                        shadow={true}
                                    >
                                        Não exibir
                                    </Button>
                                </div>
                            </div>
                            :
                            typeModal === 'cancelData' &&
                            <div className="gap-4 flex flex-col w-full p-4 items-start justify-center">
                                <p className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">Informe o valor de multa que será pago pelo cliente, caso não tenha multa, informe o valor 0</p>
                                <p className="text-sm text-red-400">Multa configurada neste plano: {planData?.cancellationFee}%</p>
                                <LabelInput text={'Multa'}>
                                    <CurrencyInput
                                        id={`cancellationFee`}
                                        placeHolder={`R$ ${(cancellationFee ? cancellationFee : '0').toString()?.replace('.', ',')}`}
                                        width={40}
                                        onChange={(e) => {
                                            if (parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')) < subscriptionData?.original_planValue) {
                                                setCancellationFeeError(false)
                                            }
                                            setCancellationFee(parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')))
                                        }}
                                    />
                                </LabelInput>
                                <p className={`${cancellationFeeError ? 'opacity-100' : 'opacity-0'} duration-100 transition-all text-xs text-red-600`}>A multa não pode ser maior do que o valor pago</p>
                                <LabelInput text={'Reembolso'}>
                                    <CurrencyInput
                                        id={`chargeBack`}
                                        placeHolder={`R$ ${(chargeBack ? chargeBack : '0').toString()?.replace('.', ',')}`}
                                        width={40}
                                        onChange={(e) => {
                                            setChargeBack(parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.')))
                                        }}
                                    />
                                </LabelInput>
                                <Button onClick={() => handleCancelSubscription()} shadow={true}>Confirmar</Button>
                            </div>
                }
            </ScreenModal>
            <Container>
                <TitlePage>
                    <Title text={'Detalhes da inscricão'}></Title>
                </TitlePage>
                <Body hasFooter={true}>
                    {
                        loading ?
                            <div className="h-96 flex w-full items-center justify-center">
                                <DefaultLoader />
                            </div>
                            :
                            <div className="w-full flex flex-col items-start justify-start gap-2">
                                <div className='mb-4 flex flex-col w-full border-b border-gray-300 dark:border-primaryBorderDark'>
                                    <h1 className='text-primaryDefaultLight text-base'>Dados de da inscrição</h1>
                                </div>
                                <div className="flex flex-col sm:flex-row w-full gap-2 items-start justify-start">
                                    <p className="text-sm text-titleBlackTextLight w-44 dark:text-titleGrayTextDark"><strong>Cliente: </strong></p>
                                    <p className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">{`${subscriptionData?.firstName} ${subscriptionData?.lastName}`}</p>
                                </div>
                                <div className="flex flex-col sm:flex-row w-full gap-2 items-start justify-start">
                                    <p className="text-sm text-titleBlackTextLight w-44 dark:text-titleGrayTextDark"><strong>Iniciado em: </strong></p>
                                    <p className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">{`${subscriptionData?.startDate} `}</p>
                                </div>
                                <div className="flex flex-col sm:flex-row w-full gap-2 items-start justify-start">
                                    <p className="text-sm text-titleBlackTextLight w-44 dark:text-titleGrayTextDark"><strong>Finaliza em: </strong></p>
                                    <p className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">{`${subscriptionData?.finishDate} `}</p>
                                </div>
                                <div className="flex flex-col sm:flex-row w-full gap-2 items-start justify-start">
                                    <p className="text-sm text-titleBlackTextLight w-44 dark:text-titleGrayTextDark"><strong>Criado em: </strong></p>
                                    <p className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">{`${subscriptionData?.created_at} `}</p>
                                </div>
                                <div className="flex flex-col sm:flex-row w-full gap-2 items-start justify-start">
                                    <p className="text-sm text-titleBlackTextLight w-44 dark:text-titleGrayTextDark"><strong>Plano: </strong></p>
                                    <p className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">{`${planData?.planName} `}</p>
                                </div>
                                <div className="flex flex-col sm:flex-row w-full gap-2 items-start justify-start">
                                    <p className="text-sm text-titleBlackTextLight w-44 dark:text-titleGrayTextDark"><strong>Valor do plano: </strong></p>
                                    <p className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">{`${subscriptionData?.planValue} `}</p>
                                </div>
                                {
                                    !subscriptionData?.active &&
                                    <div className="flex flex-col sm:flex-row w-full gap-2 items-start justify-start">
                                        <p className="text-sm text-titleBlackTextLight w-44 dark:text-titleGrayTextDark"><strong>Multa paga: </strong></p>
                                        <p className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">{`${currencyPtBr(subscriptionData?.cancellationFee)} `}</p>
                                    </div>
                                }

                                <div className="flex flex-col sm:flex-row w-full gap-2 items-start justify-start">
                                    <p className="text-sm text-titleBlackTextLight w-44 dark:text-titleGrayTextDark"><strong>Reembolso: </strong></p>
                                    <p className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">{`${currencyPtBr(subscriptionData?.chargeBack)} `}</p>
                                </div>
                                <div className="flex flex-col sm:flex-row w-full gap-2 items-start justify-start">
                                    <p className="text-sm text-titleBlackTextLight w-44 dark:text-titleGrayTextDark"><strong>Valor total: </strong></p>
                                    <p className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">{`${currencyPtBr(subscriptionData?.totalValue)} `}</p>
                                </div>
                                <div className="flex flex-col sm:flex-row w-full gap-2 items-start justify-start">
                                    <p className="text-sm text-titleBlackTextLight w-44 dark:text-titleGrayTextDark"><strong>Pago: </strong></p>
                                    <p className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">{subscriptionData?.numberOfInstallments}x de {(subscriptionData?.totalValue / subscriptionData?.numberOfInstallments).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                                </div>
                                <div className="flex flex-col sm:flex-row w-full gap-2 items-start justify-start">
                                    <p className="text-sm text-titleBlackTextLight w-44 dark:text-titleGrayTextDark"><strong>Forma de pagamento: </strong></p>
                                    <p className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">{subscriptionData?.payments_method}</p>
                                </div>
                                <div className="flex flex-col sm:flex-row w-full gap-2 items-start justify-start">
                                    <p className="text-sm text-titleBlackTextLight w-44 dark:text-titleGrayTextDark"><strong>Status: </strong></p>
                                    {
                                        subscriptionData?.active ?
                                            <p className="text-xs text-zinc-100 p-1 bg-green-500 rounded-lg">Ativo</p>
                                            :
                                            <p className="text-xs text-zinc-100 p-1 bg-gray-500 rounded-lg">Inativo/Cancelado</p>
                                    }
                                </div>
                                <div className="flex flex-col sm:flex-row w-full gap-2 items-start justify-start">
                                    <p className="text-sm text-titleBlackTextLight w-44 dark:text-titleGrayTextDark"><strong>Pagamento: </strong></p>
                                    {
                                        (!subscriptionData?.active && subscriptionData?.original_status === 3) ? <p className="text-xs text-zinc-100 p-1 bg-gray-500 rounded-lg">Pagamento não realizado</p> :
                                            subscriptionData?.original_status === 0 ? <p className="text-xs text-zinc-100 p-1 bg-blue-500 rounded-lg">Aguardando pagamento</p> :
                                                subscriptionData?.original_status === 1 && !subscriptionData?.active ? <p className="text-xs text-zinc-100 p-1 bg-green-300 rounded-lg">Pago | Inativo</p> :
                                                    subscriptionData?.original_status === 1 && subscriptionData?.active ? <p className="text-xs text-zinc-100 p-1 bg-green-500 rounded-lg">Pago | Realizado</p> :
                                                        subscriptionData?.original_status === 2 && <p className="text-xs text-zinc-100 p-1 bg-gray-500 rounded-lg">Cancelado</p>
                                    }
                                </div>
                                {
                                    subscriptionData?.active ?
                                        <div className="mt-6 flex flex-row flex-wrap items-start justify-start w-full">
                                            {
                                                (userData[0]?.permissions?.includes("activitiesSubscription:unsubscribe") || userData[0]?.typeAccess_id < 3) &&
                                                <Tippy content={<p>Cancelar esta inscricão</p>}
                                                    arrow={true}
                                                    animation='shift-away'
                                                    placement='top'
                                                    delay={100}>
                                                    <div onClick={() => { setTypeModal('cancelConfirm'); setShowUniversalModal(true); setCancellationFee(0); setChargeBack(0) }} className="p-2 hover:bg-gray-100 hover:dark:bg-zinc-600 rounded-md cursor-pointer flex flex-row gap-2 items-center justify-center">
                                                        <FaRegWindowClose className="text-lg text-titleBlackTextLight dark:text-titleGrayTextDark font-light cursor-pointer" />
                                                        <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">Cancelar</a>
                                                    </div>
                                                </Tippy>
                                            }
                                            {
                                                subscriptionData?.original_status === 1
                                                &&
                                                <Tippy content={<p>Impressão do recibo</p>}
                                                    arrow={true}
                                                    animation='shift-away'
                                                    placement='top'
                                                    delay={100}>
                                                    <div onClick={() => { setTypeModal('termsInReceiptModal'); setShowUniversalModal(true) }} className="p-2 hover:bg-gray-100 hover:dark:bg-zinc-600 rounded-md cursor-pointer flex flex-row gap-2 items-center justify-center">
                                                        <FaList className="text-lg text-titleBlackTextLight dark:text-titleGrayTextDark font-light cursor-pointer" />
                                                        <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">Imprimir recibo</a>
                                                    </div>
                                                </Tippy>
                                            }
                                        </div>
                                        :
                                        <div className="mt-6 flex flex-row flex-wrap items-start justify-start w-full">
                                            <p className="text-titleBlackTextLight dark:text-titleGrayTextDark text-sm">Cancelado</p>
                                        </div>
                                }
                            </div>
                    }
                </Body>
                <Footer>
                    <div className="flex w-full h-full items-center justify-start">
                        <Button shadow={true} approval={false} onClick={() => navigate('/activitiesActions')}>Voltar</Button>
                    </div>
                </Footer>
            </Container>
        </>

    )
}
