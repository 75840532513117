import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';
import { responseError } from '../utils/responsesFunctions/error.response';
import { DefaultCallsSchedules } from '../services/api/schedules/defaultCalls.schedules';
import { DefaultCallFinancial } from '../services/api/financial/defaultCalls.financial';
import { DefaultCallsAuth } from '../services/api/auth/defaultCalls.auth';
import { useGlobalContext } from './globalContext';

const ApiContext = createContext();

export function ApiProvider({ children }) {
    const { 
        setShowNotificationModal, 
        setShowNotificationModalSuccess, 
        setShowNotificationModalText,
        setHasExpiredPlan ,
        setLoadingAuth
    } = useGlobalContext()
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const api = axios.create({ 
        baseURL:process.env.REACT_APP_ENV == 'production' ? 'https://api.agendarapido.com' 
            :process.env.REACT_APP_ENV == 'homolog' ? 'https://api.agendamailou.com'
            : 'http://localhost:3331' 
    })
    const api_financial = axios.create({ 
        baseURL:process.env.REACT_APP_ENV == 'production' ? 'https://api.agendarapido.com' 
            :process.env.REACT_APP_ENV == 'homolog' ? 'https://api.agendamailou.com'
            : 'http://localhost:3332'
    })
    const api_auth = axios.create({ 
        baseURL:process.env.REACT_APP_ENV == 'production' ? 'https://api.agendarapido.com' 
            :process.env.REACT_APP_ENV == 'homolog' ? 'https://api.agendamailou.com'
            : 'http://localhost:3330'
    })
    const api_adm = axios.create({ 
        baseURL:process.env.REACT_APP_ENV == 'production' ? 'https://api.agendarapido.com' 
            :process.env.REACT_APP_ENV == 'homolog' ? 'https://api.agendamailou.com'
            : 'http://localhost:3339'
    })

    const defaultCallsSchedules = new DefaultCallsSchedules(api)
    const defaultCallsAuth = new DefaultCallsAuth(api_auth)
    const defaultCallsFinancial = new DefaultCallFinancial(api_financial)
    const defaultCallsAdm = new DefaultCallFinancial(api_adm)

    const validErrors = (err) => {
        if (err.response?.data?.statusCode == 406) {
            setHasExpiredPlan(true)
        }

        if (err.response?.data?.statusCode == 404 || err.response?.status == 404) {
            return Promise.resolve(err.response);
        }

        if (err.response?.data?.statusCode == 401 || err.response?.status == 401) {
            setLoadingAuth(false)
            return Promise.resolve(err.response);
        }

        setShowNotificationModal(true)
        setShowNotificationModalSuccess(false)

        if (responseError(err).length > 0) {
            setShowNotificationModalText(responseError(err))
        } else {
            setShowNotificationModalText('Erro inesperado')
        }
    }

    api.interceptors.response.use(
        (response) => {
            setLoading(false);
            return response;
        },

        err => {
            validErrors(err)
            return Promise.reject(err);
        }
    )

    api_auth.interceptors.response.use(
        (response) => {
            setLoading(false);
            return response;
        },

        err => {
            validErrors(err)
            return Promise.reject(err);
        }
    )

    api_financial.interceptors.response.use(
        (response) => {
            setLoading(false);
            return response;
        },

        err => {           
            validErrors(err)
            return Promise.reject(err);
        }
    )

    api_adm.interceptors.response.use(
        (response) => {
            setLoading(false);
            return response;
        },
        err => {        
            validErrors(err)
            return Promise.reject(err);
        }
    )

    return (
        <ApiContext.Provider value={{
            api, 
            api_financial, 
            api_auth, 
            api_adm, 
            error, 
            loading,
            defaultCallsSchedules,
            defaultCallsAuth,
            defaultCallsFinancial
        }}>
            {children}
        </ApiContext.Provider>
    );
}

export function useApiContext() {
    return useContext(ApiContext);
}
