import React, { useState, useRef, useEffect } from 'react'
//components
import { Container } from '../../../../components/container/container'
import { ImQrcode } from 'react-icons/im'
import { IoNewspaperOutline } from 'react-icons/io5'
import { BsCreditCard, BsCash, BsPencilSquare, BsCreditCard2Front } from 'react-icons/bs'
import { FaFilter, FaSearch, FaBan, FaEdit, FaCloudDownloadAlt } from 'react-icons/fa'
import { Body } from '../../../../components/container/Body'
import { TitlePage } from '../../../../components/titlePages/title.page'
import { Title } from '../../../../components/titlePages/title'
import { LabelInput } from '../../../../components/label/label.input'
import { Input } from '../../../../components/input/input'
import { InputAutoComplete } from '../../../../components/input/input.autocomplete'
import { TableDefault } from '../../../../components/table/table.default'
import { TableMobile } from '../../../../components/table/table.mobile'
import { Button } from '../../../../components/buttons/button.default'
import { useNavigate } from 'react-router-dom'
import { formattTimeToShow } from '../../../../services/api/callAPIsFunctions/defaultCalls.api'
import { useAuth } from '../../../../contexts/useAuth'
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/shift-away.css';
import { ReservationEdit } from './reservation.edit'
import { useScreenSizeContext } from '../../../../contexts/screenSizeContext'
import { DefaultLoader } from '../../../../components/loaders/defaultLoader'
import { useApiContext } from '../../../../contexts/ApiInterceptorContext'

export function Reservation() {

    const {defaultCallsSchedules, api} = useApiContext()
    const { userData } = useAuth()
    const [userPermitted, setUserPermitted] = useState(false)
    const { screenY } = useScreenSizeContext()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [filteredSchedules, setFilteredSchedules] = useState([])
    const [filteredSchedulesToReport, setFilteredSchedulesToReport] = useState([])
    const [serviceList, setServiceList] = useState([])
    const [filteredServices, setFilteredServices] = useState([])
    const [categoriesList, setCategoriesList] = useState([])
    const tableThead = [
        {
            "name": 'Nome',
            "original_name": 'users_id'
        },
        {
            "name": 'ID da reserva',
            "original_name": 'id'
        },
        {
            "name": 'Solicitado em',
            "original_name": 'created_at'
        },
        {
            "name": 'Tipo',
            "original_name": 'isRecurrent'
        },
        {
            "name": 'Data da reserva',
            "original_name": "startDate"
        },

        {
            "name": "Serviço/espaço",
            "original_name": "services_id"
        },
        {
            "name": 'Situação',
            "original_name": "status"
        },
        {
            "name": 'Pagamento',
            "original_name": "paymentMethod",
            "table": "payments"
        }

    ]
    //filters search
    const [showFilterModal, setShowFilterModal] = useState(false)
    const [showInternalFilterModal, setShowInternalFilterModal] = useState(false)
    const [paginationFilteredFields, setPaginationFilteredFields] = useState({})
    const [paginationHistFilteredFields, setPaginationHistFilteredFields] = useState({})
    const [paginationHistFilteredFieldsArr, setPaginationHistFilteredFieldsArr] = useState([])
    const tableFilterModal = useRef()
    const [totalPages, setTotalPages] = useState(1)
    const statusList = [
        { id: 0, name: "Reservando" },
        { id: 1, name: "Pago | Realizado" },
        { id: 2, name: "Cancelado" },
        { id: 3, name: "Aguardando pagamento" },
    ]

    const [inEdit, setInEdit] = useState(false)
    const [editData, setEditData] = useState([])

    const filters = [
        {
            "name": 'ID da Reserva',
            "original_name": 'schedules_id',
            "type": 'text'
        },
        {
            "name": 'ID do Pagamento',
            "original_name": 'payments_id',
            "type": 'text'
        },
        {
            "name": 'Nome',
            "original_name": 'schedule_users_firstname',
            "type": 'text'
        },
        {
            "name": 'Sobrenome',
            "original_name": 'schedule_users_lastname',
            "type": 'text'
        },
        {
            "name": 'CPF',
            "original_name": 'cpf',
            "type": 'text'
        },
        {
            "name": 'Matricula',
            "original_name": 'registrationCode',
            "type": 'text'
        },
        {
            "name": 'Status',
            "original_name": 'scheduleStatus',
            "type": 'object',
            "data": statusList,
            "optionList": ['name'],
            "selectedLabel": 'name',
            "optionToFilter": 'id'
        },
        {
            "name": 'Contrato assinado',
            "original_name": 'contractSigned',
            "type": 'object',
            "data": [{ id: true, description: "Sim" }, { id: false, description: "Não" }],
            "optionList": ['description'],
            "selectedLabel": 'description',
            "optionToFilter": 'id'
        },

        {
            "name": 'Categoria',
            "original_name": 'categories_id',
            "type": 'object',
            "data": categoriesList,
            "optionList": ['name'],
            "selectedLabel": 'name',
            "optionToFilter": 'id'
        },
        {
            "name": 'Espaço',
            "original_name": 'services_id',
            "type": 'object',
            "data": filteredServices,
            "optionList": ['name'],
            "selectedLabel": 'name',
            "optionToFilter": 'id'
        },

        {
            "name": 'Data agendada',
            "original_name": 'dateStart',
            "type": 'date'
        },
        {
            "name": 'Data criação',
            "original_name": 'created_at',
            "type": 'date'
        },
        {
            "name": 'Data',
            "original_name": 'endDate',
            "type": 'date'
        },

    ]

    async function getData(e) {
        setLoading(true)
        const schedulesData = await defaultCallsSchedules.getFilteredSchedules(userData[0]?.token, e?.filter, e?.page, e?.direction, e?.orderBy)
        const categoriesData = await defaultCallsSchedules.getCategories(userData[0].token, 1)
        const servicesData = await defaultCallsSchedules.getServices(userData[0]?.token, true)
        setTotalPages(schedulesData.data.totalPages)
        let paymentsExists = []

        schedulesData.data.data.map(schedule => {
            paymentsExists.push(schedule)
            let contractRequired = categoriesData.data?.find(categorie => categorie?.id === schedule?.categories_id)
            schedule.associateSchedules = paymentsExists.filter(paymentId => paymentId.payments_id === schedule.payments_id && paymentId.id !== schedule.id)
            schedule.original_user_firstName = schedule.schedule_users_firstname
            schedule.dueDate = schedule.dueDate ? formattTimeToShow(schedule.dueDate) : '-'
            schedule.original_payments_dueDate = schedule.payments_dueDate
            schedule.payments_dueDate = schedule.payments_dueDate ? formattTimeToShow(schedule.payments_dueDate) : '-'
            schedule.categories_contractRequired = contractRequired?.contractRequired
            schedule.isRecurrentShow = schedule.isRecurrent ? 'Recorrente' : 'Avulsa'
            schedule.scheduleStatus = schedule.payments_status === 0 ?
                <div className='p-2 '><p className='bg-yellow-500 p-1 rounded-lg text-white'>Reservando</p></div>
                : schedule.payments_status === 1 && schedule?.status === 1 && contractRequired?.contractRequired && schedule.contractSigned ?
                    <div className='p-2 '><p className='bg-green-500 p-1 rounded-lg text-white'>Pago | Realizado</p></div>
                    : schedule.payments_status === 1 && schedule?.status === 1 && contractRequired?.contractRequired && !schedule.contractSigned ?
                        <div className='p-2 flex gap-1 flex-row items-center justify-center'><p className='bg-green-400 p-1 rounded-lg text-white'>Pago | Realizado - </p><BsPencilSquare className='animate-pulse text-base text-red-500 font-bold' /></div>
                        : schedule.payments_status === 1 && schedule?.status === 1 && !contractRequired?.contractRequired ?
                            <div className='p-2 '><p className='bg-green-500 p-1 rounded-lg text-white'>Pago | Realizado</p></div>
                            : schedule.payments_status === 1 && schedule?.status !== 1 ?
                                <div className='p-2 '><p className='bg-green-300 p-1 rounded-lg text-white'>Pago | Cancelado</p></div>
                                : schedule.payments_status === 2 ?
                                    <div className='p-2 '><p className='bg-gray-500 p-1 rounded-lg text-white'>Cancelado</p></div>
                                    : schedule.payments_status === 3 && contractRequired?.contractRequired && !schedule.contractSigned ?
                                        <div className='p-2 flex gap-1 items-center justify-center'><p className='bg-blue-400 p-1 rounded-lg text-white'>Aguardando pagamento</p><a><BsPencilSquare className='animate-pulse text-base text-red-500 font-bold'></BsPencilSquare></a></div>
                                        :
                                        <div className='p-2 '><p className='bg-blue-400 p-1 rounded-lg text-white'>Aguardando pagamento</p></div>


            schedule.paymentStatus = schedule.payments_status === 0 ? <div className=' bg-yellow-500 p-1 rounded-lg text-white'><p className='bg-yellow-500 p-1 rounded-lg text-white'>Reservando</p></div>
                : schedule.payments_status === 1 ? <div className='p-2 '><p className='bg-green-500 rounded-lg p-1 text-white'>Pago</p></div>
                    : schedule.payments_status === 2 ? <div className='p-2 '><p className='bg-gray-500 rounded-lg p-1 text-white'>Cancelado</p></div>
                        : <div className='p-2 '><p className='bg-blue-400 rounded-lg p-1 text-white'>Aguardando pagamento</p></div>

            schedule.originalPaymentStatus = schedule.payments_status
            const showName = `${schedule.schedule_users_id} - ${schedule.schedule_users_firstname} ${schedule.schedule_users_lastname}`
            schedule.schedule_users_firstname = showName
            schedule.schedule_users_firstnameShowTable = showName.length > 30 ? showName.substring(0, 30) + '...' : showName 
            const scheduleDate = schedule.startDate
            schedule.startDateReceipt = schedule.startDate
            schedule.startDate = <p>{scheduleDate} | {schedule.startHour} - {schedule.finishHour}</p>
            schedule.startDateToShowInTable = <p>{scheduleDate} <br/> {schedule.startHour} - {schedule.finishHour}</p>

            schedule.paymentMethod = (schedule.payments_paymentMethod === 'CE' || schedule.payments_paymentMethod === 'C') ? <div className='p-1 flex flex-row items-center justify-center gap-2 text-lg'><BsCreditCard /><p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Cartão de crédito</p></div>
                : schedule.paymentMethod = schedule.payments_paymentMethod === 'CD' ? <div className='p-1 flex flex-row items-center justify-center gap-2 text-lg'><BsCreditCard2Front /><p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Cartão de débito</p></div>
                    : schedule.paymentMethod = schedule.payments_paymentMethod === 'P' ? <div className='p-1 flex flex-row items-center justify-center gap-2 text-lg'><ImQrcode /><p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Pix</p></div>
                        : schedule.paymentMethod = schedule.payments_paymentMethod === 'D' ? <div className='p-1 flex flex-row items-center justify-center gap-2 text-lg'><BsCash /><p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Dinheiro</p></div>
                            : schedule.paymentMethod = schedule.payments_paymentMethod === 'F' ? <div className='p-1 flex flex-row items-center justify-center gap-2 text-lg'><IoNewspaperOutline /><p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Desconto em folha</p></div>
                                : <div className='p-1 flex flex-row items-center justify-center gap-2 text-lg'><p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>-</p></div>

        })
        
        if (schedulesData.error) {
            setLoading(false)
            setShowNotication(schedulesData.data)
            return
        }
        setFilteredSchedules(schedulesData.data.data)
        setCategoriesList(categoriesData.data)
        setServiceList(servicesData.data)
        setFilteredServices(servicesData.data)
        setLoading(false)
    }

    async function handleClearFilter() {
        setLoading(true)
        setPaginationHistFilteredFieldsArr([]);
        setPaginationHistFilteredFields([])
        let fields = {}
        filters.map((column, index) => {
            fields[column.original_name] = { id: index, original_name: column.original_name, value: '' }
        })
        setPaginationFilteredFields(fields)
        getData();
        await new Promise(resolve => setTimeout(resolve, 200))
        setLoading(false)
    }

    useEffect(() => {
        if (!inEdit && paginationHistFilteredFieldsArr?.length === 0) {
            getData()
        }
    }, [inEdit])

    useEffect(() => {

        let modal = document.getElementById('tableFilterModal')
        if (modal) {
            modal.addEventListener('click', (e) => handleChangeModalStatus(e, ''))
            document.addEventListener('keydown', (e) => handleChangeModalStatus(e, 'keyboard'))
        }

        async function animateFilterModal(status) {

            let fields = {}
            filters.map((column, index) => {
                fields[column.original_name] = { id: index, original_name: column.original_name, value: '' }
            })
            setPaginationFilteredFields(fields)
            if (status) {
                let histFields = {}
                filters.map((column, index) => {
                    histFields[column.original_name] = { id: index, original_name: column.original_name, value: '' }
                })
                setPaginationHistFilteredFields(fields)
                setShowFilterModal(true)
                await new Promise(resolve => setTimeout(resolve, 200))
                return setShowInternalFilterModal(true)
            }
            setShowFilterModal(false)
            setShowInternalFilterModal(false)
        }

        animateFilterModal(showFilterModal)

        if (modal) {
            return () => { modal.removeEventListener('click', (e) => handleChangeModalStatus(e)); modal.removeEventListener('keydown', (e) => handleChangeModalStatus(e)) }
        }

    }, [showFilterModal])

    function customFilter() {

        let ToFilterData = []
        let fieldsKeys = Object.keys(paginationFilteredFields)
        //percorre os filtros informados pelo usuario para enviar no endpoint da API
        fieldsKeys.map((key) => {
            if (paginationFilteredFields[key]?.value !== '') {
                ToFilterData.push(paginationFilteredFields[key])
            }
        })
        if (ToFilterData?.length > 0) {
            setPaginationHistFilteredFieldsArr(ToFilterData)
        }
        //chama a API com os parametros informados pelo usuario
        setFilteredSchedulesToReport(ToFilterData)
        getData({ filter: { filterObject: ToFilterData }, direction: false })
        setShowFilterModal(false)
    }

    function handleChangeModalStatus(e, type) {

        if (e.code === 'Escape' && type === 'keyboard') {
            setShowFilterModal(false)
        }

        if (!tableFilterModal?.current?.contains(e.target) && type !== 'keyboard') {
            setShowFilterModal(false)
        }
    }

    function orderTableData(e) {
        const ToFilterData = []
        const fieldsKeys = Object.keys(paginationHistFilteredFields)

        fieldsKeys.map((key) => {
            if (paginationHistFilteredFields[key].value !== '') {
                ToFilterData.push(paginationHistFilteredFields[key])
            }
        })

        if (ToFilterData?.length > 0) {
            setPaginationHistFilteredFieldsArr(ToFilterData)
            return getData({ filter: { filterObject: ToFilterData }, direction: e?.direction, page: e.page, orderBy: e?.filter?.column })
        }
        getData(e)
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            customFilter()
        }
    }

    function handleEdit(reservation) {
        setEditData(reservation)
        setInEdit(true)
    }

    if (inEdit) {
        return <ReservationEdit setInEdit={async (e) => {
            setLoading(true)
            await new Promise(resolve => setTimeout(resolve, 60))
            setInEdit(e.edit);
            setLoading(false)
        }} data={editData} />
    }

    function handleChangeObjectFilter(column, e) {

        if(column.name === 'Categoria'){
            setFilteredServices(serviceList.filter(service => service.categories_id === e.id))
        }
        setPaginationFilteredFields({ ...paginationFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e[column.optionToFilter] } });
        setPaginationHistFilteredFields({ ...paginationHistFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e[column.optionToFilter] } });
    }

    return (
        <Container>
            <TitlePage>
                <div className='flex flex-row justify-between w-full'>
                    <Title text={'Reservas'} />
                    <Button userData={userData} module={'schedules:create'} shadow={true} onClick={() => navigate('/reservationCreate')}>+ Nova reserva</Button>
                </div>
            </TitlePage>
            <Body>
                {
                    showFilterModal &&
                    <div id='tableFilterModal' className='fixed left-0 top-0 z-[500] w-full h-full flex flex-col items-center justify-center'>
                        <div className='w-full h-full flex flex-col items-center justify-center relative' >
                            <div ref={tableFilterModal} className={`${showInternalFilterModal ? 'rounded-lg bg-bgPrimaryLight w-full sm:w-auto dark:bg-secondaryDefaultDark opacity-1 translate-x-0' : 'translate-x-[1000px] bg-transparent opacity-0'} absolute z-[501] p-6  boxShadow transition-all duration-300 flex flex-col items-center justify-center`}>
                                <div className='flex sm:hidden absolute top-3 right-3'>
                                    <Button onClick={() => setShowFilterModal(false)}>X</Button>
                                </div>
                                <div className='w-full text-5xl text-primaryDefaultLight flex flex-col items-center justify-center mb-8'>
                                    <FaSearch />
                                    <p className='text-lg text-primaryDefaultLight font-bold'>Filtros</p>
                                </div>
                                <form onKeyUp={(e) => handleKeyPress(e)} style={{ maxHeight: screenY - 230 + 'px' }} className='overflow-x-auto flex flex-col gap-1 w-full'>
                                    {
                                        filters.map(column => {
                                            return column.original_name !== 'dateStart' && column.original_name !== 'endDate' &&
                                                <div className='flex flex-col sm:flex-row gap-4'>
                                                    <div className='w-28'>
                                                        <p className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>{column.name}</p>
                                                    </div>
                                                    {
                                                        column.type === 'text' ?
                                                            <LabelInput>
                                                                <Input value={paginationFilteredFields[column.original_name]['value']} onChange={(e) => {
                                                                    setPaginationFilteredFields({ ...paginationFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                                    setPaginationHistFilteredFields({ ...paginationHistFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                                }}></Input>
                                                            </LabelInput>
                                                            :
                                                            column.type === 'object' ?
                                                                <LabelInput>
                                                                    <InputAutoComplete data={column.data} optionList={column.optionList} selectedLabel={column.selectedLabel} onChange={(e) => {
                                                                        handleChangeObjectFilter(column, e)
                                                                    }}></InputAutoComplete>
                                                                </LabelInput>
                                                                :
                                                                column.type === 'date' &&
                                                                <LabelInput>
                                                                    <Input type={`date`} value={paginationFilteredFields[column.original_name]['value']} onChange={(e) => {
                                                                        setPaginationFilteredFields({ ...paginationFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                                        setPaginationHistFilteredFields({ ...paginationHistFilteredFields, [`${column.original_name}`]: { original_name: column.original_name, name: column.name, value: e.target.value } });
                                                                    }} />
                                                                </LabelInput>
                                                    }
                                                </div>
                                        })}
                                    <div className='flex flex-col sm:flex-row gap-4'>
                                        <div className='w-28'>
                                            <a className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Periodo</a>
                                        </div>
                                        <LabelInput>
                                            <Input width={40} type={`date`} value={paginationFilteredFields['dateStart']['value']} onChange={(e) => {
                                                setPaginationFilteredFields({ ...paginationFilteredFields, ['dateStart']: { ...paginationFilteredFields['dateStart'], value: e.target.value } });
                                                setPaginationHistFilteredFields({ ...paginationHistFilteredFields, ['dateStart']: { ...paginationFilteredFields['dateStart'], value: e.target.value } });
                                            }} />
                                        </LabelInput>
                                        <a className='mt-2 mx-3'>A</a>
                                        <LabelInput>
                                            <Input width={40} type={`date`} value={paginationFilteredFields['endDate']['value']} onChange={(e) => {
                                                setPaginationFilteredFields({ ...paginationFilteredFields, ['endDate']: { ...paginationFilteredFields['endDate'], value: e.target.value } });
                                                setPaginationHistFilteredFields({ ...paginationHistFilteredFields, ['endDate']: { ...paginationFilteredFields['endDate'], value: e.target.value } });
                                            }} />
                                        </LabelInput>
                                    </div>
                                </form>
                                <div className='flex w-full flex-col items-end justify-start mt-6'>
                                    <Button type="submit" onClick={() => customFilter()} shadow={true}>Pesquisar</Button>
                                </div>
                            </div>
                            <div className={`${showInternalFilterModal ? 'bg-gray-700 opacity-75' : 'bg-transparent opacity-0'} absolute h-full w-full transition-all duration-300`}>
                            </div>
                        </div>
                    </div>
                }
                <div className='flex flex-row w-full items-center justify-start gap-4'>
                    <Button shadow={true}>
                        <div className={`p-1 cursor-pointer flex flex-row items-center justify-center gap-2`} onClick={() => setShowFilterModal(true)}>
                            <FaFilter />
                            Filtrar
                        </div>
                    </Button>
                    <Tippy content={'Ir para tela de relatório de reservas'}
                        arrow={true}
                        animation='shift-away'
                        placement='top'
                        delay={100}>
                        <div>
                            <a id='downloadLink'></a>
                            <Button shadow={true}>
                                <div className={`p-1 cursor-pointer flex flex-row items-center justify-center gap-2`} onClick={() => navigate('/reports/schedules')}>
                                    <FaCloudDownloadAlt />
                                    Relatório
                                </div>
                            </Button>
                        </div>
                    </Tippy>
                    {
                        paginationHistFilteredFieldsArr?.length > 0 &&
                        <Tippy content={'Excluir filtro'}
                            arrow={true}
                            animation='shift-away'
                            placement='top'
                            delay={100}>
                            <div className='cursor-pointer flex flex-row gap-2 text-xl text-primaryDefaultLight hover:brightness-150' onClick={() => { handleClearFilter() }}>
                                <FaBan />
                            </div>
                        </Tippy>
                    }
                </div>


                <div id='reservationTableContent' className='hidden sm:flex flex-col items-center justify-center w-full boxShadow overflow-y-auto rounded-lg'>
                    <TableDefault
                        pagination={true}
                        totalPages={totalPages}
                        onClick={(e) => handleEdit(e)}
                        title={tableThead}
                        data={filteredSchedules}
                        collumns={["schedule_users_firstnameShowTable", "id", "created_at", 'isRecurrentShow','startDateToShowInTable', "services_name", "scheduleStatus", "paymentMethod"]}
                        loading={loading}
                        filter={(e) => orderTableData(e)} />
                </div>
                <div id='reservationMobileTableContent' className={`flex sm:hidden flex-col items-center justify-start w-full overflow-y-auto h-full`}>
                    {
                        loading ?
                            <DefaultLoader />
                            :
                            <TableMobile
                                onClick={(e) => e.func.execute(e.data)}
                                title={tableThead}
                                loading={loading}
                                pagination={true}
                                totalPages={totalPages}
                                filter={(e) => orderTableData(e)}
                                data={filteredSchedules}
                                collumns={["schedule_users_firstnameShowTable", "id", "created_at", 'isRecurrentShow', 'startDateToShowInTable', "services_name", "scheduleStatus", "paymentMethod"]}
                                functions={[{ "title": "Editar", "icon": <FaEdit />, "execute": handleEdit }]}
                            />
                    }
                </div>
            </Body>
        </Container>
    )
}