import { responseError } from "../../../utils/responsesFunctions/error.response";
import { formattTimeToShow } from "../callAPIsFunctions/defaultCalls.api";

export class DefaultCallsAuth {

    #api_auth;

    constructor(api_auth, token) {
        this.#api_auth = api_auth
    }

    async getUserTerms(tokenKey, userId) {

        let endpoint = `/api/v1/auth/userTerms/users_id/${userId}`

        try {

            const data = await this.#api_auth.get(endpoint, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: data?.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //isAuth token
    async isAuth(tokenKey) {

        try {
            const isAuthData = await this.#api_auth.get('/api/auth/isAuth', {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                statusCode: isAuthData.data.status,
                data: isAuthData.data
            }
        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    statusCode: error.response.data.statusCode,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    statusCode: error.response.data.statusCode,
                    data: 'Erro inesperado'
                }
            }
        }

    }

    //get customerAlerts
    async getCustomerAlerts(tokenKey) {

        let endpoint = `/api/v1/auth/customersAlerts`

        try {

            const data = await this.#api_auth.get(endpoint, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: data?.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getuserTerms
    async getUserTerms(tokenKey, userId) {

        let endpoint = `/api/v1/auth/userTerms/users_id/${userId}`

        try {

            const data = await this.#api_auth.get(endpoint, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: data?.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }


    async getSectors(tokenKey, status = null) {

        try {
            const apiData = await this.#api_auth.get(`/api/v1/auth/sectors`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    async getSectorsById(tokenKey, id) {

        try {
            const apiData = await this.#api_auth.get(`/api/v1/auth/sectors/${id}`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getAssociationsCategoriesDiscount
    async getAssociationsCategoriesDiscount(tokenKey, categorieId) {

        try {
            const associationsCategoriesDiscount = await this.#api_auth.get(`/api/v1/auth/associationsCategoriesDiscount/categories_id/${categorieId}`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: associationsCategoriesDiscount.data.data
            }


        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //isAuth token
    async isAuth(tokenKey) {
        console.log("tokenKey:", tokenKey)
        const isAuthData = await this.#api_auth.get('/api/auth/isAuth', {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return isAuthData

    }

    //get customers
    async getCustomers(tokenKey) {

        const customersData = await this.#api_auth.get(`/api/v1/auth/customers`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        console.log(customersData)
        return {
            error: false,
            data: customersData.data.data[0]
        }

    }



    //typeAccess get
    async getTypeAccess(tokenKey) {
        const typeAccessData = await this.#api_auth.get('/api/v1/auth/typeAccess', {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })

        return {
            error: false,
            data: typeAccessData.data.data
        }
    }

    //get users
    async getUsers(tokenKey) {

        try {
            const userData = await this.#api_auth.get(`/api/v1/auth/users`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            userData.data.data.map((apiData, index) => {
                userData.data.data[index].birthday = formattTimeToShow(apiData.birthday)
                userData.data.data[index].created_at = formattTimeToShow(apiData.created_at)
            })
            let newData = userData.data.data
            return {
                error: false,
                data: newData
            }

        } catch (error) {
            console.log(error)
        }
    }


    //get clients
    async getUsersFilteredClients(tokenKey, filter, page, direction, active) {

        let params = `/api/v1/auth/users?typeAccess_id=4&direction=${direction ? 'asc' : 'desc'}`

        params = filter?.column ? params.concat(`&orderBy=${filter.column}`) : params
        params = page > 0 ? params.concat(`&page=${page}`) : params
        params = active ? params.concat(`&active=true`) : params.concat(`&active=false`)

        if (filter?.filterObject?.length > 0) {

            filter.filterObject.map(toFilter => {
                params = params.concat(`&${toFilter.original_name}=${toFilter.value}`)
            })
        }
        try {
            const userData = await this.#api_auth.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            userData.data.data.map((apiData, index) => {
                userData.data.data[index].name = userData.data.data[index].firstName + ' ' + userData.data.data[index].lastName
                userData.data.data[index].birthday = formattTimeToShow(apiData.birthday)
                userData.data.data[index].created_at = formattTimeToShow(apiData.created_at)
            })
            const newData = {
                data: userData.data.data, 
                totalPages: userData.data.totalPages, 
                quantity: userData.data.quantity 
            }
            return {
                error: false,
                data: newData
            }
        } catch (error) {
            console.log(error)
        }
    }

    //get users
    async getUsersFilteredUsers(tokenKey, filter, page, direction, active) {

        let params = `/api/v1/auth/users?typeAccess_id=2,3&direction=${direction ? 'asc' : 'desc'}`

        params = filter?.column ? params.concat(`&orderBy=${filter.column}`) : params
        params = page > 0 ? params.concat(`&page=${page}`) : params
        params = active ? params.concat(`&active=true`) : params.concat(`&active=false`)

        if (filter?.filterObject?.length > 0) {
            filter.filterObject.map(toFilter => {
                params = params.concat(`&${toFilter.original_name}=${toFilter.value}`)
            })
        }
        try {
            const userData = await this.#api_auth.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            userData.data.data.map((apiData, index) => {
                userData.data.data[index].name = userData.data.data[index].firsName + ' ' + userData.data.data[index].lastName
                userData.data.data[index].birthday = formattTimeToShow(apiData.birthday)
                userData.data.data[index].created_at = formattTimeToShow(apiData.created_at)
            })
            const newData = { 
                data: userData.data.data.filter(user => user.id !== -1), 
                totalPages: userData.data.totalPages, 
                quantity: userData.data.quantity 
            }
            return {
                error: false,
                data: newData
            }
        } catch (error) {
            console.log(error)
        }
    }

    //getAllUsersFiltered
    async getAllUsersFiltered(tokenKey, filter, page, direction, active, activeAssociation) {

        let params = `/api/v1/auth/users?typeAccess_id=1,2,3,4&direction=${direction ? 'asc' : 'desc'}`

        params = filter?.column ? params.concat(`&orderBy=${filter.column}`) : params
        params = page > 0 ? params.concat(`&page=${page}`) : params
        params = active ? params.concat(`&active=true`) : params.concat(`&active=false`)
        params = activeAssociation ? params.concat(`&activeAssociation=true`) : params

        if (filter?.filterObject?.length > 0) {

            filter.filterObject.map(toFilter => {
                params = params.concat(`&${toFilter.original_name}=${toFilter.value}`)
            })
        }
        try {
            const userData = await this.#api_auth.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            userData.data.data.map((apiData, index) => {
                userData.data.data[index].birthday = formattTimeToShow(apiData.birthday)
                userData.data.data[index].created_at = formattTimeToShow(apiData.created_at)
            })

            const newData = { 
                data: userData.data.data.filter(user => user.id !== -1), 
                totalPages: userData.data.totalPages 
            }

            return {
                error: false,
                data: newData
            }
        } catch (error) {
            console.log(error)
        }
    }

    //user especific
    async getUserEspecific(tokenKey, userId) {
        const userData = await this.#api_auth.get(`/api/v1/auth/users/${userId}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: userData.data.data
        }
    }

    //delete user especific
    async deleteUserEspecific(tokenKey, userId) {

        const userData = await this.#api_auth.delete(`/api/v1/auth/users/${userId}`, {
            headers: {
                Authorization: `Bearer ${tokenKey}`
            }
        })
        return {
            error: false,
            data: userData.data.data
        }
    }

    //user permissions get
    async getPermissions(tokenKey) {

        try {
            const permissionsData = await this.#api_auth.get('/api/v1/auth/permissions', {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: permissionsData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //get associations
    async getAssociations(tokenKey, status = null) {

        try {
            const associationsData = await this.#api_auth.get(`/api/v1/auth/associations${[1, 0].includes(status) ? `?active=${status}` : ''}`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: associationsData.data.data
            }

        } catch (error) {

            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getUserDependentsByUsersId
    async getUserDependentsByUsersId(tokenKey, usersId) {

        try {
            const data = await this.#api_auth.get(`/api/v1/auth/usersDependents/users_id/${usersId}`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: data.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //get associations by id
    async getAssociationsById(tokenKey, id) {

        try {
            const associationsData = await this.#api_auth.get(`/api/v1/auth/associations/${id}`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: associationsData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //delete associations by id
    async associationsDelete(tokenKey, id) {

        try {
            const associationsData = await api_auth.delete(`/api/v1/auth/associations/${id}`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: associationsData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //permissionGroups get
    async getPermissionGroups(tokenKey) {

        try {
            const permissionGroupsData = await this.#api_auth.get('/api/v1/auth/permissionGroups', {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            permissionGroupsData.data.data.map((apiData) => {
                apiData.created_at = formattTimeToShow(apiData.created_at)
            })
            return {
                error: false,
                data: permissionGroupsData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //permissionGroups especific get
    async getPermissionGroupEspecific(tokenKey, id) {

        try {
            const permissionGroupData = await this.#api_auth.get(`/api/v1/auth/permissionGroups/${id}`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: permissionGroupData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //myProfile
    async getMyProfile(tokenKey) {

        try {
            const userData = await this.#api_auth.get(`/api/v1/auth/myProfile`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: userData.data.data
            }


        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }



    //getCustomersDetails
    async getCustomersDetails(tokenKey) {
        try {
            const customerDetails = await this.#api_auth.get(`/api/v1/auth/customers/details`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: customerDetails.data.data
            }
        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }


    //getBlockedUsers
    async getBlockedUsers(tokenKey, filter, page, direction) {

        let params = `/api/v1/auth/blockedUsers?direction=${direction ? 'asc' : 'desc'}`
        params = filter?.column ? params.concat(`&orderBy=${filter.column}`) : params
        params = page > 0 ? params.concat(`&page=${page}`) : params

        if (filter?.filterObject?.length > 0) {
            filter.filterObject.map(toFilter => {
                params = params.concat(`&${toFilter.original_name}=${toFilter.value}`)
            })
        }
        try {
            const data = await this.#api_auth.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: data.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    async getBlockedUsersByUserId(tokenKey, userId) {

        let params = `/api/v1/auth/blockedUsers/users_id/${userId}`
        try {
            const data = await this.#api_auth.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: data.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getBlockedUsersByBlockedId
    async getBlockedUsersByBlockedId(tokenKey, blockedId) {
        let endpoint = `/api/v1/auth/blockedUsers/blocked_id/${blockedId}`
        try {
            const data = await this.#api_auth.get(endpoint, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: data.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }


    //getuserTerms
    async getUserTerms(tokenKey, userId) {

        let endpoint = `/api/v1/auth/userTerms/users_id/${userId}`

        try {

            const data = await this.#api_auth.get(endpoint, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: data?.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //getUserTermsDetails
    async getUserTermsDetails(tokenKey, terms_id) {

        let endpoint = `/api/v1/auth/userTerms/terms_id/${terms_id}`

        try {

            const data = await this.#api_auth.get(endpoint, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: data?.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }



    //getImportProgress
    async getImportProgress(tokenKey, id) {

        let params = `/api/v1/auth/users/import/progress`
        try {

            const data = await this.#api_auth.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            return {
                error: false,
                data: data.data?.data
            }

        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    async getCompanies(tokenKey) {

        let params = `/api/v1/auth/companies`
        try {
            const data = await this.#api_auth.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            console.log(data)

            let formattedData = []
            if (data.data.data?.length > 0) {
                formattedData = data.data.data.map(companie => { 
                    return { 
                        ...companie, 
                        created_at: formattTimeToShow(companie.created_at) 
                    } 
                })
            }
            return {
                error: false,
                data: formattedData
            }

        } catch (error) {
            console.log(error)
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    async getCompanyById(tokenKey, id) {

        let params = `/api/v1/auth/companies/${id}`
        try {
            const data = await this.#api_auth.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            let formattedData = []
            if (data.data.data?.length > 0) {
                formattedData = data.data.data.map(companie => { 
                    return { 
                        ...companie, 
                        created_at: formattTimeToShow(companie.created_at) 
                    } 
                })
            }

            return {
                error: false,
                data: formattedData
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }
}