import { useEffect, useState } from "react"
import { useThemeContext } from "../../../contexts/themeContext"
import { LabelInput } from "../../../components/label/label.input"
import { Input } from "../../../components/input/input"
import CurrencyInput from "../../../components/input/inputCurrencyPtBr"
import { Container } from "../../../components/container/container"
import { TitlePage } from "../../../components/titlePages/title.page"
import { Title } from "../../../components/titlePages/title"
import { Body } from "../../../components/container/Body"
import { useApiContext } from "../../../contexts/ApiInterceptorContext"
import { useNavigate, useParams } from "react-router-dom"
import { useAuth } from "../../../contexts/useAuth"
import { InputAutoComplete } from "../../../components/input/input.autocomplete"
import { FaArrowDown, FaArrowUp, FaCheck, FaPlus } from "react-icons/fa"
import { FaX } from "react-icons/fa6"
import { NewTableDefault } from "../../../components/table/new.table.default"
import Tippy from '@tippyjs/react'
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/shift-away.css'
import { DefaultLoader } from "../../../components/loaders/defaultLoader"
import { validateFields } from "../../../utils/form.validator"
import { currencyPtBr } from "../../../utils/formatCurrency/currencyTextPTBR"
import { Footer } from "../../../components/footer/Footer"
import { Button } from "../../../components/buttons/button.default"

export function FinancialBalancesActions() {
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const { api_financial, defaultCallsFinancial, defaultCallsAuth, defaultCallsSchedules } = useApiContext()
    const { userData } = useAuth()
    const { users_id } = useParams()
    const navigate = useNavigate()
    const [filter, setFilter] = useState({})
    const [totalUserBalance, setTotalUserBalance] = useState(0)
    const [balanceData, setBalanceData] = useState({
        agreements_id: undefined,
        entryType: '',
        description: '',
        value: '',
    })
    const [userSelected, setUserSelected] = useState({})
    const [agreementsData, setAgreementsData] = useState([]) // chamar api de convênios
    const entryTypeOptions = [
        { value: 'E', label: 'Entrada' },
        { value: 'S', label: 'Saída' },
    ]
    const [newAction, setNewAction] = useState(false)
    const [loading, setLoading] = useState(false)
    const [useFinancialUsersBalance, setUseFinancialUsersBalance] = useState()

    useEffect(() => {
        getData()
    }, [])

    async function getData() {
        try {
            const data = await defaultCallsFinancial.getFinancialUsersBalancePerUsersId(userData[0]?.token, users_id)
            const foundUser = await defaultCallsAuth.getUserEspecific(userData[0]?.token, users_id)
            const agreements = await defaultCallsSchedules.getAgreements(userData[0]?.token)


            const agreementsFinancial = await Promise.all(agreements?.data?.data?.map(async agreement => {
                const agreementIsFinancial = await defaultCallsFinancial.getFinancialAgreementPerId(userData[0]?.token, agreement.id)
                if (agreementIsFinancial?.data[0]?.canSendIncome) {
                    return agreementIsFinancial?.data[0]?.agreements_id
                }
            }))

            setAgreementsData(agreements?.data?.data.filter(agreement => {
                if(agreementsFinancial.filter(Boolean).includes(agreement.id)) return agreement                
            }))
            console.log(agreements?.data?.data.filter(agreement => {
                if(agreementsFinancial.filter(Boolean).includes(agreement.id)) return agreement                
            }))
        
            const foundFinancialUser = await defaultCallsFinancial.getFinancialUsersPerId(userData[0]?.token, users_id)

            if (foundFinancialUser?.data?.length > 0) {
                const useBalance = foundFinancialUser?.data?.find(us => us?.users_id == foundUser?.data[0]?.id)?.useFinancialUsersBalance
                setUseFinancialUsersBalance(useBalance)
            }

            const formattedTotalBalance = data?.data?.reduce((acc, item) => {
                const value = Number(item?.value) || 0
                return item?.entryType === "E" ? acc + value : acc - value
            }, 0)

            if (data?.data?.length > 0) {
                setUserSelected(data?.data[0])
            } else {
                setUserSelected(foundUser?.data[0])
            }
            setTotalUserBalance(formattedTotalBalance)
        } catch (error) {
            console.log(error)
        }
    }

    async function handleSubmit() {
        try {
            let requiredFields = [
                { "name": "entryType", "value": balanceData?.entryType, "required": true, type: "string" },
                { "name": "value", "value": balanceData?.value, "required": true, type: "number" },
            ]
            if (validateFields(requiredFields)?.length > 0) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowUniversalModal(false)
                setLoading(false)
                return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos em vermelho')
            }
            setNewAction(false)
            setLoading(true)

            if (balanceData?.entryType === "E") {
                const newTotal = totalUserBalance + Number(balanceData?.value)
                setTotalUserBalance(newTotal)
            } else {
                const newTotal = totalUserBalance - Number(balanceData?.value)
                if (newTotal >= 0) {
                    setTotalUserBalance(newTotal)
                } else {
                    setShowNotificationModalSuccess(false)
                    setShowNotificationModal(true)
                    setShowUniversalModal(false)
                    setLoading(false)
                    return setShowNotificationModalText("Usuário não possui saldo para prosseguir com essa ação.")
                }
            }      
            await api_financial.post('/api/v1/financial/usersBalance', {
                users_id: users_id,
                entryType: balanceData?.entryType,
                value: balanceData?.value,
                description: balanceData?.description || undefined,
                agreements_id: balanceData?.agreements_id || undefined
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowUniversalModal(false)
            return setShowNotificationModalText('Sucesso ao realizar novo lançamento.')
        } catch (error) {
            console.log(error)
        } finally {
            setBalanceData({
                agreements_id: undefined,
                entryType: '',
                description: '',
                value: '',
            })
            setLoading(false)
        }
    }

    return (
        <Container>
            <TitlePage>
                <div className="flex flex-row justify-between w-full items-center">
                    <Title text={"Saldo de associado"} />
                </div>
            </TitlePage>
            <Body>
                <div className="w-full h-full flex flex-col items-start justify-start gap-6">
                    <div className="w-full flex flex-row items-center justify-between">
                        <div className="flex flex-row items-end gap-2">
                            <p className="text-base">Usuário selecionado:</p>
                            <p className="text-base text-titleBlackTextLight">{userSelected?.firstName} {userSelected?.lastName}</p>
                        </div>
                        <p className='text-base text-green-700'>
                            Saldo total: {currencyPtBr(totalUserBalance)}
                        </p>
                    </div>
                    {
                        newAction ?
                            loading ?
                                <DefaultLoader />
                                :
                                <div className="w-full h-full flex flex-col items-start justify-start gap-4">
                                    <div className="w-full h-full flex flex-row flex-wrap items-center gap-4">
                                        <LabelInput text={'Ação desejada: *'}>
                                            <InputAutoComplete
                                                data={entryTypeOptions}
                                                id={'entryType'}
                                                preSelectedValue={entryTypeOptions.find(opt => opt.value == balanceData.entryType)?.label || ''}
                                                onChange={(e) => {
                                                    setBalanceData(prev => ({ ...prev, entryType: e.value }))
                                                }}
                                                selectedLabel={'label'}
                                                optionList={['label']}
                                            />
                                        </LabelInput>
                                        <LabelInput text={'Valor: *'}>
                                            <CurrencyInput
                                                id={`value`}
                                                className='pl-2 border border-gray-400 border-opacity-75 
                                                outline-none
                                                sm:w-96 w-full
                                                text-sm sm:text-sm
                                                transition-all duration-200
                                                focus:shadow-borderShadow
                                                h-10 sm:h-8
                                                disabled:bg-gray-300 placeholder:text-black
                                                dark:text-titleGrayTextDark dark:bg-secondaryDefaultDark dark:border-secondaryBorderDark'
                                                placeHolder={balanceData?.value ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(balanceData?.value) : 'R$ 0,00'}
                                                onChange={(e) => {
                                                    balanceData.value = parseFloat(e.target.value.replace('R$', '').replace('.', '').replace(',', '.'))
                                                    setBalanceData(prev => ({ ...prev, value: balanceData.value }))
                                                }}
                                            />
                                        </LabelInput>
                                        <LabelInput text={'Descrição: '}>
                                            <Input
                                                id='description'
                                                type={'text'}
                                                value={balanceData?.description}
                                                charLimit={255}
                                                onChange={(e) => setBalanceData(prev => ({ ...prev, description: e.target.value }))}
                                            />
                                        </LabelInput>
                                        <LabelInput text={'Convênio: '}>
                                            <InputAutoComplete
                                                data={agreementsData}
                                                id={'agreements_id'}
                                                preSelectedValue={agreementsData.find(opt => opt.agreements_id == balanceData.agreements_id)?.label || ''}
                                                onChange={(e) => {
                                                    console.log(e)
                                                    setBalanceData(prev => ({ ...prev, agreements_id: e.id }))
                                                }}
                                                selectedLabel={'title'}
                                                optionList={['title']}
                                            />
                                        </LabelInput>
                                    </div>
                                    <div className="w-full flex flex-row items-center justify-between">
                                        <p className='flex flex-row items-center gap-[4px] cursor-pointer hover:brightness-50 duration-100 text-red-700' onClick={() => setNewAction(false)}>
                                            Cancelar
                                            <FaX className='text-xs mt-[4px]' />
                                        </p>
                                        <p className='flex flex-row items-center gap-[4px] cursor-pointer hover:brightness-50 duration-100 text-green-700' onClick={() => handleSubmit()}>
                                            Confirmar
                                            <FaCheck className='text-xs mt-[4px]' />
                                        </p>
                                    </div>
                                </div>
                            :
                            <></>
                    }
                    <div className="w-full h-full flex flex-col gap-4">
                        <div className="w-full flex flex-row items-end justify-between">
                            <h2 className="text-lg text-primaryDefaultLight">Movimentações</h2>
                            {
                                useFinancialUsersBalance ?
                                    <Tippy content={<p>Novo lançamento</p>}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='top'
                                        delay={100}>
                                        <div onClick={() => setNewAction(true)} className="p-2 hover:bg-gray-100 rounded-md cursor-pointer flex flex-row gap-2 items-center justify-center">
                                            <FaPlus className="text-lg text-titleBlackTextLight dark:text-titleGrayTextDark font-light cursor-pointer" />
                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">Lançar</a>
                                        </div>
                                    </Tippy> :
                                    <Tippy content={<p>Este usuário não possui configuração de saldo</p>}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='top'
                                        delay={100}>
                                        <div className="p-2 hover:bg-gray-100 rounded-md cursor-not-allowed flex flex-row gap-2 items-center justify-center opacity-50">
                                            <FaPlus className="text-lg text-titleBlackTextLight dark:text-titleGrayTextDark font-light cursor-pointer" />
                                            <a className="text-sm text-titleBlackTextLight dark:text-titleGrayTextDark">Lançar</a>
                                        </div>
                                    </Tippy>
                            }
                        </div>
                        {
                            loading ?
                                <div className="w-full flex flex-col items-center justify-center">
                                    <DefaultLoader />
                                </div>
                                :
                                <NewTableDefault
                                    key={'table-financialBalancesPerUserId'}
                                    endpoint={`/api/v1/financial/usersBalance/${users_id}`}
                                    token={userData[0]?.token}
                                    module="financial"
                                    columns={[
                                        {
                                            description: 'ID',
                                            field: 'id',
                                            type: 'number'
                                        },
                                        {
                                            description: 'Tipo',
                                            field: 'entryType',
                                            type: 'string',
                                            newFieldValue: (item) => {
                                                return (
                                                    item?.entryType === "S" ?
                                                        <div className="w-full flex flex-row items-center justify-center gap-2">
                                                            <h2>Saída</h2>
                                                            <h2><FaArrowDown size={14} className="text-red-500" /></h2>
                                                        </div>
                                                        : item?.entryType === "E" ?
                                                            <div className="w-full flex flex-row items-center justify-center gap-2">
                                                                <h2>Entrada</h2>
                                                                <h2><FaArrowUp size={14} className="text-green-500" /></h2>
                                                            </div>
                                                            : "-"
                                                )
                                            }
                                        },
                                        {
                                            description: 'Descrição',
                                            field: 'description',
                                            type: 'string',
                                        },
                                        {
                                            description: 'Valor',
                                            field: 'value',
                                            type: 'money',
                                        },
                                        {
                                            description: 'Associado',
                                            field: 'firstName',
                                            type: 'string',
                                        },
                                        {
                                            description: 'CPF',
                                            field: 'cpf',
                                            type: 'string',
                                        },
                                        {
                                            description: 'Associação',
                                            field: 'associationName',
                                            type: 'string',
                                        },
                                        {
                                            description: 'Criado em',
                                            field: 'created_at',
                                            type: 'date',
                                        },
                                    ]}
                                    mapApiData={['data']}
                                    filters={filter}
                                    usePagination={true}
                                    mapQuantityOfRegisters={['quantity']}
                                    mapTotalPages={['totalPages']}
                                />
                        }
                    </div>
                </div>
            </Body>
            <Footer>
                <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                    <Button shadow={true} approval={false} onClick={() => navigate(-1)}>Cancelar</Button>
                </div>
            </Footer>
        </Container>
    )
}
