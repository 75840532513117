//hooks
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//components
import { FaBan, FaFilter, FaSearch } from "react-icons/fa";
import { Body } from "../../../components/container/Body";
import { Container } from "../../../components/container/container";
import { Title } from "../../../components/titlePages/title";
import { TitlePage } from "../../../components/titlePages/title.page";
import { Button } from "../../../components/buttons/button.default";
//contexts
import { useThemeContext } from "../../../contexts/themeContext";
import { useAuth } from "../../../contexts/useAuth";
import { LabelInput } from "../../../components/label/label.input";
import { Input } from "../../../components/input/input";
import { InputAutoComplete } from "../../../components/input/input.autocomplete";
import Tippy from "@tippyjs/react";
import { NewTableDefault } from "../../../components/table/new.table.default";
import { maskCnpj } from "../../../utils/mask/maskCnpj";
import { maskCpf } from "../../../utils/mask/maskCpf";
import { ScreenModal } from "../../../components/modals/notification/screenModal";

export function ClientsAndSuppliers() {

    const navigate = useNavigate()
    const { setShowUniversalModal } = useThemeContext()
    const { userData } = useAuth()
    const [filterConstructor, setFilterConstructor] = useState({})
    const [filter, setFilter] = useState({
        active: true
    })

    const personTypeOption = [
        { id: "PJ", description: "Pessoa Jurídica" },
        { id: "PF", description: "Pessoa Física" }
    ]
    const clientAndSupplierOption = [
        { id: true, description: "Sim" },
        { id: false, description: "Não" }
    ]

    function setAndSaveFilter(filterObj) {
        setFilter(filterObj)
        sessionStorage.setItem('lastFilter:cashFlow', JSON.stringify(filterObj))
    }

    return (
        <>
            <ScreenModal>
                <div className={`pb-10 px-10 boxShadow duration-300 flex flex-col`}>
                    <div className='w-full text-xl text-primaryDefaultLight flex flex-row items-center gap-2 justify-center mb-6'>
                        <FaSearch />
                        <p className='text-lg text-primaryDefaultLight font-bold'>Filtros</p>
                    </div>
                    <form onSubmit={e => {
                        e.preventDefault()
                        setAndSaveFilter({ ...filterConstructor })
                        setShowUniversalModal(false)
                    }} id="clientAndSuppliersFilters" className="flex flex-col justify-center items-center">
                        <div className="flex flex-row gap-3 items-center justify-center">
                            <p className="text-gray-400 text-sm w-24">Nome </p>
                            <LabelInput>
                                <Input
                                    id={'name'}
                                    value={filterConstructor?.name}
                                    onChange={(e) => setFilterConstructor({ ...filterConstructor, name: e?.target.value })}
                                    disabled={false}
                                    width={72}
                                />
                            </LabelInput>
                        </div>
                        <div className="flex flex-row gap-3 items-center justify-center">
                            <p className="text-gray-400 text-sm w-24">Tipo</p>
                            <LabelInput>
                                <InputAutoComplete
                                    width={72}
                                    data={personTypeOption}
                                    id={'personType'}
                                    value={filterConstructor?.personType ? personTypeOption.filter(ps => ps.id == filterConstructor?.personType)[0]?.description : ''}
                                    onChange={(e) => {
                                        console.log(e)
                                        setFilterConstructor({ ...filterConstructor, personType: e?.id })
                                    }}
                                    selectedLabel={'description'}
                                    optionList={['description']}
                                />
                            </LabelInput>
                        </div>
                        <div className="flex flex-row gap-3 items-center justify-center">
                            <p className="text-gray-400 text-sm w-24">É cliente</p>
                            <LabelInput>
                                <InputAutoComplete
                                    width={72}
                                    data={clientAndSupplierOption}
                                    id={'isClient'}
                                    value={filterConstructor?.isClient ? clientAndSupplierOption.filter(ps => ps.id == filterConstructor?.isClient)[0]?.description : ''}
                                    onChange={(e) => setFilterConstructor({ ...filterConstructor, isClient: e?.id })}
                                    selectedLabel={'description'}
                                    optionList={['description']}
                                />
                            </LabelInput>
                        </div>
                        <div className="flex flex-row gap-3 items-center justify-center">
                            <p className="text-gray-400 text-sm w-24">É fornecedor</p>
                            <LabelInput>
                                <InputAutoComplete
                                    width={72}
                                    data={clientAndSupplierOption}
                                    id={'isSupplier'}
                                    value={filterConstructor?.isSupplier ? clientAndSupplierOption.filter(ps => ps.id == filterConstructor?.isSupplier)[0]?.description : ''}
                                    onChange={(e) => setFilterConstructor({ ...filterConstructor, isSupplier: e?.id })}
                                    selectedLabel={'description'}
                                    optionList={['description']}
                                />
                            </LabelInput>
                        </div>
                        <div className='flex w-full flex-row justify-between items-center mt-6'>
                            <Button approval={false} onClick={() => {
                                setShowUniversalModal(false)
                            }} shadow={true}>Cancelar</Button>
                            <Button form="clientAndSuppliersFilters" type={"submit"} shadow={true}>Pesquisar</Button>
                        </div>
                    </form>
                </div>
            </ScreenModal>
            <Container>
                <TitlePage>
                    <div className="flex flex-row justify-between w-full">
                        <Title text='Clientes e fornecedores'></Title>
                        <Button module={"financialClientAndSuppliers:create"} userData={userData} shadow={true} onClick={() => navigate('/financial/clientsAndSuppliersCreate')}>+ Novo</Button>
                    </div>
                </TitlePage>
                <Body>
                    <div className="flex flex-col gap-4 w-full">
                        <div className="flex items-center justify-between mb-4">
                            <div className='flex items-center justify-start w-full'>
                                <button
                                    className={`font-bold shadow-md shadow-gray-400 text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28 flex flex-col items-center justify-center ${filter?.active === true ? 'bg-primaryLightSuccess hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-primaryLightSuccess'} dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25`}
                                    onClick={() => setFilter({ ...filter, active: true })}
                                >Ativos</button>
                                <button
                                    className={`font-bold shadow-md shadow-gray-400 text-titleGrayTextLight text-sm pl-2 pr-2 h-10 md:h-6 w-full md:w-28 flex flex-col items-center justify-center ${filter?.active === false ? 'bg-primaryLightSuccess hover:shadow-gray-400' : 'bg-titleGrayTextDark hover:shadow-primaryLightSuccess'} dark:bg-primaryDarkSuccess transition-all duration-200 dark:shadow-xl dark:shadow-black/25`}
                                    onClick={() => setFilter({ ...filter, active: false })}
                                >Inativos</button>
                            </div>
                            <div className="w-fit flex just items-center gap-4">
                                <Button shadow={true} >
                                    <div className={` cursor-pointer flex items-center justify-end gap-2`} onClick={() => setShowUniversalModal(true)}>
                                        <FaFilter />
                                        Filtrar
                                    </div>
                                </Button>
                                {
                                    Object.keys(filterConstructor).length > 0 &&
                                    <Tippy content={'Excluir filtro'}
                                        arrow={true}
                                        animation='shift-away'
                                        placement='top'
                                        delay={100}>
                                        <div className='cursor-pointer flex flex-row gap-2 text-xl text-primaryDefaultLight hover:brightness-150' onClick={() => {
                                            setFilterConstructor({})
                                            setAndSaveFilter({ active: filter?.active })
                                        }}>
                                            <FaBan />
                                        </div>
                                    </Tippy>
                                }
                            </div>
                        </div>
                        <div className=' flex-col gap-4 items-start justify-start w-full boxShadow overflow-y-auto'>
                            <NewTableDefault
                                key={'table-clientsAndSuppliers'}
                                endpoint="/api/v1/financial/utils/usersClientsAndSuppliers"
                                token={userData[0]?.token}
                                module="financial"
                                columns={[
                                    {
                                        description: 'Código',
                                        field: 'linked_id',
                                        type: 'string',
                                        newFieldValue: (e) => {
                                            return e.linked == 'financialClientsAndSuppliers'
                                                ? `Clientes e Fornecedores: ${e.linked_id}`
                                                : `Usuário: ${e.linked_id}`
                                        }
                                    },
                                    {
                                        description: 'Documento',
                                        field: 'cnpj_cpf',
                                        type: 'string',
                                        newFieldValue: (e) => {
                                            return e.personType == 'PJ'
                                                ? maskCnpj(e.cnpj_cpf)
                                                : e?.cnpj_cpf ? maskCpf(e.cnpj_cpf) : '-'
                                        }
                                    },
                                    {
                                        description: 'Nome',
                                        field: 'name',
                                        type: 'string',
                                    },
                                    {
                                        description: 'E-mail',
                                        field: 'email',
                                        type: 'string',
                                    },
                                    {
                                        description: 'Cliente',
                                        field: 'isClient',
                                        type: 'boolean',
                                    },
                                    {
                                        description: 'Fornecedor',
                                        field: 'isSupplier',
                                        type: 'boolean',
                                    },
                                    {
                                        description: 'Data criação',
                                        field: 'created_at',
                                        type: 'date',
                                    },
                                ]}
                                filters={filter}
                                usePagination={true}
                                mapQuantityOfRegisters={['quantity']}
                                mapTotalPages={['totalPages']}
                                onClickInTableLine={(row) => {
                                    if (row?.linked === "financialClientsAndSuppliers") {
                                        return navigate(`/financial/clientsAndSuppliersEdit/${row?.linked_id}`)
                                    } else {
                                        return navigate(`/clientsEdit/${row?.linked_id}`)
                                    }
                                }}
                            />
                        </div>
                    </div>
                </Body>
            </Container>
        </>
    )
}
