// import LoginLogo from '../../assets/logos/loginLogo.png'
import { useEffect, useRef, useState } from 'react'
//services
import { useAuth } from '../../../../contexts/useAuth'

import defaultImg from '../../../../assets/default_assets/soccer.webp'
//components
import { LabelInput } from '../../../../components/label/label.input'
import { InputAutoComplete } from '../../../../components/input/input.autocomplete'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import { Button } from '../../../../components/buttons/button.default'
//utils
import moment from 'moment'
import { useThemeContext } from '../../../../contexts/themeContext'
import { responseError } from '../../../../utils/responsesFunctions/error.response'
import { hourArr } from '../../../../utils/hour/generateHourArr'
import { maskHours } from '../../../../utils/mask/maskHours'
import { useScreenSizeContext } from '../../../../contexts/screenSizeContext'
import { useNavigate } from 'react-router-dom'
import { Container } from '../../../../components/container/container'
import { Body } from '../../../../components/container/Body'
import { TitlePage } from '../../../../components/titlePages/title.page'
import { Title } from '../../../../components/titlePages/title'
import { useApiContext } from '../../../../contexts/ApiInterceptorContext'

// Dados iniciais de exemplo (locais e horários disponíveis)
const initialLocations = [
    {
        id: 1,
        name: 'Local 1',
        availableHours: ['09:00', '10:00', '11:00', '14:00']
    },
    {
        id: 2,
        name: 'Local 2',
        availableHours: ['08:00', '12:00', '15:00', '16:00']
    },
    {
        id: 3,
        name: 'Local 3',
        availableHours: ['07:00', '09:00', '13:00', '17:00']
    }
];

export const ClientCalendar = () => {

    const { defaultCallsSchedules, api } = useApiContext()
    const { userData } = useAuth()
    const { setShowUniversalModal, setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const { screenX, screenY } = useScreenSizeContext()

    const navigate = useNavigate()

    const [selectedCategorie, setSelectedCategorie] = useState('')
    const [daysfetched, setDaysFetched] = useState({})
    const [loading, setLoading] = useState(true)
    const [loadingSchedules, setLoadingSchedules] = useState(true)
    const [loadingBoard, setLoadingBoard] = useState(true)
    const [categoriesList, setCategoriesList] = useState([])
    const [servicesList, setServicesList] = useState([])
    const [servicesListHistoric, setServicesListHistoric] = useState([])
    const [hourSelected, setHourSelected] = useState('')
    const [selectedDate, setSelectedDate] = useState(moment().format("YYYY-MM-DD"))
    const [serviceDaysToOpen, setServiceDaysToOpen] = useState('')
    const calendarRef = useRef(null)
    const [dragging, setDragging] = useState(false)
    const [showScheduleMandatory, setShowScheduleMandatory] = useState(false)
    const [touchSchedule, setTouchSchedule] = useState(false)
    const [touchStartTime, setTouchStartTime] = useState(null)
    const [startDate, setStartDate] = useState(moment())
    const [showIndex, setShowIndex] = useState([0])
    const [custtomerAttendanceDetails, setCusttomerAttendanceDetails] = useState('')
    const [selectedHours, setSelectedHours] = useState({})
    const [servicePhotos, setServicesPhotos] = useState([])

    useEffect(() => {
        getData()
    }, [])

    async function getSchedules(date) {

        setLoadingSchedules(true)
        try {
            const schedulesData = await api.get(`/api/public/${userData[0].customers_code}/schedulesAndBlocks/date/${date}`)
            setLoadingSchedules(false)
            console.log(schedulesData.data.data)
            return schedulesData.data.data

        } catch (error) {
            setLoadingSchedules(false)
        }
    }

    async function getData() {

        try {
            setLoading(true)

            const mySchedulesPendings = await defaultCallsSchedules.getSchedulesClientsMyPendings(userData[0]?.token)
            //valida se cliente tem alguma reserva em andamento
            if (mySchedulesPendings?.data?.payment?.length > 0) {

                return navigate(`/reservation`)
            }
            const categoriesData = await defaultCallsSchedules.getCategories(userData[0].token)
            const customerAttendanceAPI = await defaultCallsSchedules.getCustomersAttendance(userData[0].token)

            setCusttomerAttendanceDetails(customerAttendanceAPI.data[0])
            setCategoriesList(categoriesData.data)

            setLoading(false)
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleSelecteCategorie(e) {

        if(!e.id) return
        
        setLoadingSchedules(true)
        setLoadingBoard(true)

        try {
            const servicesData = await defaultCallsSchedules.getServicesByCategoriesId(userData[0].token, e.id)
            const schedulesData = await getSchedules(moment().format('YYYY-MM-DD'))

            const customerAttendanceForThisAssociation = custtomerAttendanceDetails.associationsParameters.filter(associationParam => {
                return associationParam.associations_id === userData[0].associations_id 
                    && associationParam.categories_id === e.id
            })
            setServiceDaysToOpen(customerAttendanceForThisAssociation[0]?.daysToOpen)

            servicesData.data.map(service => {
                service.schedules = schedulesData?.schedules.filter(schedule => schedule.services_id === service.id)
                service.blocks = schedulesData?.blocks.filter(schedule => schedule.services_id === service.id)
            })

            await Promise.all(servicesData.data.map(async (service) => {
                const hours = await defaultCallsSchedules.getSchedulesHours(userData[0].token, selectedDate, service.id)
                service.hours = hours?.data
            }))

            setServicesList(servicesData.data)
            setServicesListHistoric(servicesData.data)

            if (!touchSchedule) {
                setShowIndex([...servicesData.data.map((servive, i) => { return i })])
                setLoadingSchedules(false)
                setLoadingBoard(false)
            } else {
                setLoadingSchedules(false)
                setLoadingBoard(false)
            }
        } catch (error) {
            console.log(error)
            // adiconar modal de erro
        }

        setSelectedCategorie(e)
    }

    function scrollToCurrentHour() {

        const currentHour = moment().hour();
        const elementToScroll = document.getElementById(`hour-${currentHour}`)
        if (elementToScroll) {
            elementToScroll.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
    }

    const handleHourSelect = (locationId, hour) => {
        setSelectedHours(prev => {
            let updatedSelectedHours = { ...prev };

            const selectedForLocation = updatedSelectedHours[locationId] || []

            const updatedHours = selectedForLocation.includes(hour)
                ? selectedForLocation.filter(h => h !== hour)
                : [...selectedForLocation, hour]


            if (updatedHours.length === 0) {
                delete updatedSelectedHours[locationId]
            } else {
                updatedSelectedHours[locationId] = updatedHours
            }

            return updatedSelectedHours;
        })
    }

    const handlePrev = () => {
        let maxDays = screenX > 850 ? 7 : 3
        setStartDate(startDate.clone().subtract(maxDays, 'days'))
    }

    const handleNext = () => {
        let maxDays = screenX > 850 ? 7 : 3
        setStartDate(startDate.clone().add(maxDays, 'days'))
    }

    const renderDays = () => {
        const days = [];
        let maxDays = screenX > 1000 ? 14 : screenX > 850 ? 8 : screenX > 500 ? 7 : 5
        for (let i = 0; i < maxDays; i++) {

            const currentDay = startDate.clone().add(i, 'days');

            //valida se dia está liberado para reservas
            const thisDayBlocked = (
                currentDay.startOf('day').isBefore(moment().startOf('day')) ||
                currentDay.startOf('day').diff(moment().startOf('day'), 'days') > serviceDaysToOpen
            )

            days.push(
                <div
                    onClick={() => {
                        {
                            if (!thisDayBlocked) {
                                setSelectedDate(currentDay.format("YYYY-MM-DD"));
                                setHourSelected('')
                                findSchedulesAndHoursByCachedData(currentDay.format("YYYY-MM-DD"));
                            }
                        }
                    }}
                    key={i}
                    className={`rounded-full w-10 h-10 ${(
                        thisDayBlocked
                    ) ? 'bg-zinc-200' : selectedDate === currentDay.format("YYYY-MM-DD") ?
                        'bg-blue-300 text-white' : 'bg-blue-100'}  
                    p-1 flex flex-col items-center justify-center w-full
                    cursor-pointer hover:bg-blue-200
                    ${thisDayBlocked && 'cursor-not-allowed opacity-50'}
            `}>
                    <a className="text-xs">{currentDay.format("DD")}</a>
                    <a className="text-xs">{currentDay.format("MMM")}</a>
                </div>
            );
        }
        return days;
    }

    async function findSchedulesAndHoursByCachedData(date) {

        const lastFethThisdate = Object.keys(daysfetched).filter(fetchedDate => fetchedDate === date)[0]
        if (lastFethThisdate) {
            const lastFetchDiff = daysfetched[lastFethThisdate]?.lastFetch?.diff(moment(), 'seconds');
            if (lastFetchDiff < -60) {
                getNewSchedulesByDate(date)
            } else {
                setLoadingSchedules(true)
                setSelectedHours({})
                setServicesList([...daysfetched[lastFethThisdate].serviceList])
                await new Promise(resolve => setTimeout(resolve, 200))
                setLoadingSchedules(false)
            }
        } else {
            getNewSchedulesByDate(date)
        }
    }

    async function getNewSchedulesByDate(date) {

        setLoadingSchedules(true)
        setSelectedHours({})

        const schedulesData = await api.get(`/api/v1/schedules/client/readScheduesAndBlocksInDateInCustomer/date/${date}`, {
            headers: {
                Authorization: `Bearer ${userData[0].token}`
            }
        })
        const newServiceList = structuredClone(servicesListHistoric)

        newServiceList.map(service => {
            service.schedules = schedulesData.data?.data.schedules.filter(schedule => schedule.services_id === service.id)
            service.blocks = schedulesData.data?.data.blocks.filter(schedule => schedule.services_id === service.id)
        })

        await Promise.all(newServiceList.map(async (service) => {
            const hours = await defaultCallsSchedules.getSchedulesHours(userData[0].token, date, service.id)
            const ordernedHours = hours?.data?.sort((a, b) => a.startHour - b.startHour);

            const serviceDetail = await defaultCallsSchedules.getServicesEspecific(userData[0]?.token, service.id)

            if (serviceDetail.data[0]?.pictures?.length > 0) {
                const photoFile = await api.get(`api/v1/servicesPictures/services_id/${service.id}/picture/${serviceDetail.data[0]?.pictures[0]}`, {
                    responseType: 'blob',
                    responseEncoding: 'base64',
                    headers: {
                        Authorization: `Bearer ${userData[0].token}`
                    }
                })
                service.picture = URL.createObjectURL(photoFile.data)
            }
            service.hours = ordernedHours
        }))

        setServicesList([...newServiceList])
        setDaysFetched({
            ...daysfetched,
            [date]: {
                lastFetch: moment(),
                serviceList: [...newServiceList]
            }
        })

        await new Promise(resolve => setTimeout(resolve, 200))
        setLoadingSchedules(false)
        scrollToCurrentHour()

    }

    function handleSchedule() {

        const hours = selectedHours[Object.keys(selectedHours)].map(hour => { return hour.id })
        // return
        navigate(`/reservation/categories_id/${selectedCategorie?.id}/services_id/${Object.keys(selectedHours)[0]}/date/${selectedDate}?hour=${hours}`)
    }

    return (

        <Container>
            <TitlePage>
                <Title text={'Reservas'}></Title>
            </TitlePage>
            <Body>

                {
                    !selectedCategorie ?
                        <div onTouchStart={() => setTouchSchedule(true)} className="bg-white w-full h-full p-3 relative flex flex-col items-center justify-center py-16 z-10">
                            <h2 className="text-2xl font-semibold text-zinc-800">Verifique nossa <strong className="text-primaryDefaultLight">agenda!</strong></h2>
                            <a className="text-zinc-700 ">Selecione um tipo de espaço abaixo:</a>
                            <LabelInput
                                text={'Tipo'}
                            >
                                <InputAutoComplete
                                    width={'100px'}
                                    value={selectedCategorie?.name}
                                    data={categoriesList}
                                    selectedLabel={'name'}
                                    optionList={['name']}
                                    placeHolder='Categoria'
                                    onChange={e => handleSelecteCategorie(e)}
                                    id='selectedCategorie'
                                />
                            </LabelInput>
                        </div>
                        :
                        !loadingBoard &&
                        <>
                            <div className='w-full flex flex-col items-center justify-center overflow-hidden relative'>
                                <div className="absolute left-1 top-1 cursor-pointer hover:brightness-110">
                                    <Button onClick={() => {
                                        setSelectedCategorie('');
                                        setHourSelected('')
                                    }}
                                        shadow
                                        approval>
                                        Voltar
                                    </Button>
                                </div>
                                <a className='py-4'>Selecione o <strong className='text-primaryDefaultLight font-semibold'>dia desejado</strong></a>
                                <div className="bg-white px-3 py-1 md:min-w-[500px] flex relative flex-col gap-1 w-full justify-center items-center">
                                    <div className="w-full flex items-center justify-center gap-4">
                                        <button onClick={handlePrev} className="p-2 bg-gray-200 rounded-full cursor-pointer">
                                            &lt;
                                        </button>
                                        <div className="flex gap-2 items-center jsutify-center">
                                            {renderDays()}
                                        </div>
                                        <button onClick={handleNext} className="p-2 bg-gray-200 rounded-full cursor-pointer">
                                            &gt;
                                        </button>
                                    </div>
                                    {
                                        hourSelected && !touchSchedule &&
                                        <div className="absolute right-2 top-2 animate-[wiggle_.2s_ease-in-out] transition-all duration-100">
                                            <Button onClick={() => handleSchedule()} shadow className="w-14">
                                                Reservar
                                            </Button>
                                        </div>
                                    }
                                </div>
                                <h2 className="text-primariDefaultLight py-3">Horários disponíveis</h2>
                                {
                                    loadingSchedules ?

                                        <a>Carregando</a>
                                        :
                                        <div className='w-full items-start justify-start md:justify-center overflow-auto mb-2 px-5 flex flex-row gap-4 h-[100vh] md:h-full'>
                                            {servicesList.map(location => {
                                                return (
                                                    <div key={location.id} className="location flex justify-start min-h-[400px] w-[360px] min-w-[320px] mb-10 rounded-md shadow-lg items-center flex-col border border-blue-100 gap-2">
                                                        <div className='w-full rounded-t-md w-full'>
                                                            {
                                                                location?.picture ?
                                                                    <div style={{
                                                                        backgroundImage: `linear-gradient(to bottom, rgba(245, 246, 252, 0.15), 
                                                                        rgba(27, 27, 27, 0.9)), url(${location.picture})`,
                                                                    }} className='h-[200px] rounded-t transition-all duration-200 bg-cover
                                                                                    bg-no-repeat bg-center w-full h-full flex items-end justify-start px-2 pb-2'>
                                                                        <h3 className='text-xl shadow-xl p-1 rounded-lg text-white font-sembibold flex items-center justify-center'>{location.name}</h3>
                                                                    </div>
                                                                    :
                                                                    <div style={{
                                                                        backgroundImage: `linear-gradient(to bottom, rgba(245, 246, 252, 0.15), 
                                                                        rgba(27, 27, 27, 0.9)), url(${defaultImg})`,
                                                                    }} className='h-[200px] rounded-t transition-all duration-200 bg-cover
                                                                                    bg-no-repeat bg-center w-full h-full flex items-end justify-start px-2 pb-2'>
                                                                        <h3 className='text-xl shadow-xl p-1 rounded-lg text-white font-sembibold flex items-center justify-center'>{location.name}</h3>
                                                                    </div>

                                                            }
                                                        </div>
                                                        <div className="hours flex flex-wrap  w-full gap-3 p-3 max-h-[300px] overflow-y-auto">
                                                            {location.hours.length === 0 &&
                                                                <a>Nenhum horário disponível</a>}
                                                            {location.hours.map(hour => {

                                                                const isSelected = selectedHours[location.id]?.includes(hour);  // Verifica se o horário está selecionado

                                                                return (
                                                                    <div
                                                                        key={hour}
                                                                        onClick={() => handleHourSelect(location.id, hour)}
                                                                        className={`${hour.isBlocked ? 'bg-zinc-200 opacity-90 cursor-not-allowed text-zinc-500' : isSelected ? 'cursor-pointer bg-primaryDefaultLight text-white border border-primaryDefaultLight' : 
                                                                            'cursor-pointer  sm:hover:bg-secondaryDefaultLight sm:hover:text-white sm:hover:border-secondaryDefaultLight border border-zinc-100 bg-white transition-all duration-200 text-zinc-500'}  
                                                                             h-16 p-2 rounded-lg shadow-md flex flex-col gap-2 items-center justigy-center`}>

                                                                        <a className=' text-xs sm:text-sm'>{maskHours({ hour: hour.startHour })} - {maskHours({ hour: hour.finishHour })}</a>

                                                                        {
                                                                            hour.isBlocked ?
                                                                                <a className='text-xs sm:text-sm'>Indisponível</a>
                                                                                :
                                                                                <a className='text-xs sm:text-sm'>Disponível</a>
                                                                        }

                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            )}
                                        </div>
                                }
                            </div>

                            {
                                Object.keys(selectedHours)?.length > 0 &&
                                <div className='fixed bottom-16 sm:bottom-0 py-6 max-h-[150px] sm:max-h-[150px] overflow-y-auto bg-white w-full border-t border-primaryDefaultLight flex flex-col items-center justify-start'>
                                    <h3>Horários Selecionados:</h3>
                                    {Object.keys(selectedHours).map(locationId => {
                                        return (
                                            <div className='flex flex-col items-center justify-center' key={locationId}>
                                                <strong>{servicesList.find(loc => loc.id === parseInt(locationId))?.name}: </strong>
                                                {
                                                    selectedHours[locationId]?.length > 1 ?
                                                        <div className='flex flex-col gap-2'>
                                                            <a>{selectedHours[locationId]?.length} horários selecionados</a>
                                                        </div>
                                                        :
                                                        <a>{maskHours({ hour: selectedHours[locationId][0]?.startHour })} - {maskHours({ hour: selectedHours[locationId][0]?.finishHour })}</a>
                                                }
                                                <div className='mt-2'>
                                                    <Button shadow onClick={() => handleSchedule()}>Continuar</Button>
                                                </div>
                                            </div>
                                        )

                                    })}
                                </div>
                            }
                        </>
                }

            </Body>
        </Container>


    );
};

export default ClientCalendar;
