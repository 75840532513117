import React, { useContext, createContext, useState, useEffect } from 'react'
import { getCustomerAlerts, getPaymentPublicGateways, getUserTerms, isAuth } from '../services/api/callAPIsFunctions/defaultCalls.api'
import moment from 'moment'
import { FreeTrialExpiredModal } from '../components/modals/freeTrialExpiredModal'
import { ExpiratedPlanAppName } from '../components/modals/expiratedPlanAppName'
import { useGlobalContext } from './globalContext'
import { useApiContext } from './ApiInterceptorContext'

const AuthContext = createContext()

export function AuthContextProvider({ children }) {

    const { userData, setUserData,
        isFreeTrial, setIsFreeTrial,
        hasExpiredPlan, setHasExpiredPlan,
        signed, setSigned,
        loadingAuth, setLoadingAuth
     } = useGlobalContext()

    const { api_auth, defaultCallsAuth, defaultCallsSchedules } = useApiContext()   
    const [customersAlerts, setCustomersAlerts] = useState([])
    const [readCustomersAlerts, setReadCustomersAlerts] = useState(false)
    const [haveTermsOfUse, setHaveTermsOfUse] = useState(false)
    const [dontHaveEmail, setDontHaveEmail] = useState(false)

    //modulo selecionado
    const [selectedModule, setSelectedModule] = useState([1, 2])
    const userToken = localStorage.getItem('@auth:token')

    async function getCustomersData(customers_app_name) {
        try {
            const customersData = await api_auth.get(`/api/auth/public/customers/${customers_app_name}`)
            return customersData?.data?.data[0]
        } catch (error) {
            console.log(error)
            console.log(error?.response)
        }
    }

    async function verifytoken(userToken) {
        setLoadingAuth(true)

        try {
            const isAuthUser = await defaultCallsAuth.isAuth(userToken)
            if (isAuthUser?.status === 200) {
                setIsFreeTrial(isAuthUser?.data?.userData?.isFreeTrial)

                const apiUserData = []
                apiUserData.push(isAuthUser.data.userData)

                apiUserData[0].customersData = await getCustomersData(isAuthUser.data.userData.customers_app_name)
                const paymentGateway = await defaultCallsSchedules.getPaymentPublicGateways(isAuthUser.data.token)
                apiUserData[0].customersData.hasGateway = paymentGateway.data?.data?.length > 0 ? true : false
                apiUserData[0].customersData.paymentGateway = paymentGateway.data?.data?.length > 0 ? paymentGateway.data?.data[0] : []

                apiUserData[0].token = isAuthUser.data.token
                apiUserData[0].customers_id = 1
                localStorage.setItem('typeUser', 1)

                //busca os alertas do cliente caso ele ainda não tenha lido nesta sessão
                const lastLogin = localStorage?.getItem('@lastLogin')
                const lastLoginDate = lastLogin?.length > 0 ? moment(lastLogin) : moment('1900-01-01')
                const numberOfDaysSinceLastLogin = lastLoginDate.diff(moment().format("YYYY-MM-DD"))

                if (numberOfDaysSinceLastLogin < 0 && isAuthUser.data.userData?.typeAccess_id < 4) {
                    const alerts = await defaultCallsAuth.getCustomerAlerts(userToken)
                    if (alerts.data.data[0]?.message?.length > 0) {
                        setCustomersAlerts(alerts.data.data)
                    }
                    localStorage.setItem('@lastLogin', moment().format('YYYY-MM-DD'))
                }

                const terms = await defaultCallsAuth.getUserTerms(isAuthUser.data.token, apiUserData[0]?.id)
                if (terms.data?.data?.length > 0) {
                    if (apiUserData[0].email?.length === 0 || !apiUserData[0].email) {
                        setDontHaveEmail(true)
                    }
                    setUserData(apiUserData)
                    setSigned(true)
                    setLoadingAuth(false)
                    return setHaveTermsOfUse(true)
                }

                if (apiUserData[0]?.email?.length === 0 || !apiUserData[0].email) {
                    setDontHaveEmail(true)
                }
                setUserData(apiUserData)
                setSigned(true)
                setLoadingAuth(false)

            }

            if (isAuthUser?.status === 401) {
                localStorage.removeItem('@auth:token')
                localStorage.removeItem('@')
                setLoadingAuth(false)
                setSigned(false)
                return
            }
        } catch (error) {
            console.log(error)
            alert('Erro no login, contate o administrador')
            localStorage.removeItem('@auth:token')
            setSigned(false)
        }
    }

    function verifyModule() {
        let moduleSelected = localStorage.getItem('@agenda-rapido-selectedModule')
        if (moduleSelected) {
            setSelectedModule(moduleSelected)
        } else {
            localStorage.setItem('@agenda-rapido-selectedModule', '[1,2]')
        }
    }

    useEffect(() => {
        verifytoken(userToken)
        verifyModule()
    }, [])

    return (
        <AuthContext.Provider value={{
            signed, setSigned,
            userData, setUserData,
            verifytoken,
            getCustomersData,
            loadingAuth, setLoadingAuth,
            customersAlerts, setCustomersAlerts,
            readCustomersAlerts, setReadCustomersAlerts,
            dontHaveEmail, setDontHaveEmail,
            haveTermsOfUse, setHaveTermsOfUse,
            selectedModule, setSelectedModule,
            isFreeTrial, setIsFreeTrial,
            hasExpiredPlan, setHasExpiredPlan

        }}>
            {
                // controla clientes com plano trial expirado
                isFreeTrial && hasExpiredPlan &&
                <FreeTrialExpiredModal />
            }

            {
                //controla clientes com pagamentos pendentes do plano low cost
                hasExpiredPlan && !isFreeTrial &&
                <ExpiratedPlanAppName />

            }
            {children}



        </AuthContext.Provider>
    )
}

export function useAuth() {
    return useContext(AuthContext)
}