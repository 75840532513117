//hooks
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
//components
import { Button } from '../../../components/buttons/button.default'
import { Body } from '../../../components/container/Body'
import { Container } from '../../../components/container/container'
import { Input } from '../../../components/input/input'
import { LabelInput } from '../../../components/label/label.input'
import { Title } from '../../../components/titlePages/title'
import { TitlePage } from '../../../components/titlePages/title.page'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { Footer } from '../../../components/footer/Footer'
//utils
import { responseError } from '../../../utils/responsesFunctions/error.response'
//contexts
import { useThemeContext } from '../../../contexts/themeContext'
import { useAuth } from '../../../contexts/useAuth'
//services
import { validateFields } from '../../../utils/form.validator'
import { Toogle } from '../../../components/toogle/Toogle'
import { ScreenModal } from '../../../components/modals/notification/screenModal'
import { useApiContext } from '../../../contexts/ApiInterceptorContext'

export function FinancialServicesEdit() {

    const { api_financial } = useApiContext()
    const navigate = useNavigate()
    const params = useParams()
    const { userData } = useAuth()
    const [loading, setLoading] = useState(true)
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText, setShowUniversalModal } = useThemeContext()
    const [detectModal, setDetectModal] = useState('')
    const [services, setServices] = useState({ })

    async function getData() {
        setLoading(true)
        try {
        
            const serviceData = await api_financial.get(`/api/v1/financial/services/${params.id}`, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            console.log(serviceData?.data?.data[0])
            setServices(serviceData?.data?.data[0] ?? {})
            
            setLoading(false)
        } catch (error) {
            setLoading(false)   
        }
    }

    useEffect(() => {
        setShowUniversalModal(false)
        getData()
    }, [])


    async function handleSubmit() {
        setLoading(true)
        try {

            const validForm = validateFields([
                { "name": 'name', "value": services?.name, "required": true, "type": 'string' },
                { "name": 'descrition', "value": services?.descrition, "required": false, "type": 'string' },
                { "name": 'active', "value": services?.active, "required": true, "type": 'boolean' },
            ])

            if (validForm.length > 0) {
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                setShowUniversalModal(false)
                setLoading(false)
                return setShowNotificationModalText('Campos obrigatórios não preenchidos, verifique os campos em vermelho')
            }

            await api_financial.put(`/api/v1/financial/services/${params.id}`, {
                name: services?.name ? services?.name : undefined,
                description: services?.description ? services?.description : undefined,
                active: [true, false].includes(services?.active) ? services?.active : undefined,
            }, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setLoading(false)
            setShowNotificationModalSuccess(true)
            setShowNotificationModal(true)
            setShowNotificationModalText("Produto salvo com sucesso!")
            navigate(-1)
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    async function handleRemove() {

        setLoading(true)
        try {

            await api_financial.delete(`/api/v1/financial/services/${params.id}`, {
                headers: {
                    Authorization: `Bearer ${userData[0]?.token}`
                }
            })

            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            setShowNotificationModalText("Produto removido com sucesso!")
            navigate(-1)
        } catch (error) {
            setShowUniversalModal(false)
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }
    }

    return (
        <>
            {
                detectModal === 'Excluir'
                &&
                <ScreenModal>
                    <div className='p-4 flex flex-col gap-2'>
                        <a className='text-sm text-titleBlackTextLight dark:text-titleGrayTextDark'>Deseja realmente excluir este serviço?</a>
                        <div className='flex flex-row w-full items-center justify-center gap-2'>
                            <Button shadow={true} onClick={() => handleRemove()} approval={true} >Sim</Button>
                            <Button shadow={true} onClick={() => setShowUniversalModal(false)} approval={false} >Não</Button>
                        </div>
                    </div>
                </ScreenModal>
            }
            <Container>
                <TitlePage>
                    <Title text={'Editar Serviço'}></Title>
                </TitlePage>
                <Body hasFooter={true}>
                    {
                        loading ?
                            <div className='flex flex-col items-center justify-center w-full h-full'>
                                <DefaultLoader />
                            </div>
                            :
                            <>
                                <form className='flex flex-col gap-2 items-start justify-start sm:justify-center w-full'>
                                    <LabelInput text={'Nome: *'}>
                                        <Input id='name' type={'text'} value={services?.name} onChange={(e) => setServices(prev => ({ ...prev, name: e.target.value }))}></Input>
                                    </LabelInput>
                                    <LabelInput text={'Descrição:'}>
                                        <Input id='description' type={'text'} value={services?.description} onChange={(e) => setServices(prev => ({ ...prev, description: e.target.value }))}></Input>
                                    </LabelInput>
                                    {
                                        services?.applicationModules_id === 3 && 
                                        <LabelInput text={'ativo'}>
                                            <Toogle status={services?.active} onClick={() => setServices(prev => ({ ...prev, active: !services?.active }))} />
                                        </LabelInput>
                                    }
                                </form>
                            </>
                    }
                </Body>
                <Footer>
                    <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                        <Button shadow={true} approval={false} onClick={() => navigate(-1)}>Cancelar</Button>
                        <div className='flex gap-2'>
                            <Button module={'financialServices:delete'} userData={userData} shadow={true} approval={false} onClick={() => { setShowUniversalModal(true); setDetectModal("Excluir") }}>Excluir</Button>
                            <Button module={'financialServices:update'} userData={userData} shadow={true} approval={true} onClick={() => handleSubmit()}>Salvar</Button>
                        </div>
                    </div>
                </Footer>
            </Container>
        </>
    )
}