// hooks 
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
//contexts
import { useAuth } from '../../../contexts/useAuth'
import { useThemeContext } from '../../../contexts/themeContext'
// components 
import { Container } from '../../../components/container/container'
import { Title } from '../../../components/titlePages/title'
import { InputAutoComplete } from '../../../components/input/input.autocomplete'
import { TitlePage } from '../../../components/titlePages/title.page'
import { useScreenSizeContext } from '../../../contexts/screenSizeContext'
import { Button } from '../../../components/buttons/button.default'
import { Input } from '../../../components/input/input'
import { validateFields } from '../../../utils/form.validator'
import { DefaultLoader } from '../../../components/loaders/defaultLoader'
import { Body } from '../../../components/container/Body'
import { LabelInput } from '../../../components/label/label.input'
// utils 
import { validateEmail } from '../../../utils/validators/email.validator'
import { responseError } from '../../../utils/responsesFunctions/error.response'
import { justNumber } from '../../../utils/validators/justNumber'
import { mCPF } from '../../../utils/validators/cpfMask'
import { maskPhone } from '../../../utils/mask/maskPhone'
import { removeEspecialChar } from '../../../utils/validators/removeEspecialCharacter'

import { Footer } from '../../../components/footer/Footer'
import { useApiContext } from '../../../contexts/ApiInterceptorContext'

export function UserCreate() {

    const { api_auth, defaultCallsAuth } = useApiContext()
    const { userData } = useAuth()
    const { setShowNotificationModal, setShowNotificationModalSuccess, setShowNotificationModalText } = useThemeContext()
    const [permissionsData, setPermissionsData] = useState([])
    const tokenKey = localStorage.getItem('@auth:token')
    const { screenY } = useScreenSizeContext()

    const [loading, setLoading] = useState(true)
    const navegate = useNavigate()
    const [typeAccessList, setTypeAccessList] = useState([])
    const [associationsList, setAssociationsList] = useState([])
    //selectedVariables
    const [selectedUserName, setSelectedUserName] = useState('')
    const [selectedUserSubName, setSelectedUserSubName] = useState('')
    const [selectedUserEmail, setSelectedUserEmail] = useState('')
    const [selectedBirthDay, setSelectedBirthDay,] = useState('')
    const [selectedTypeAccesss, setSelectedTypeAccess] = useState('')
    const [selectedPermissionGroup, setSelectedPermissionGroup] = useState('')
    const [selectedUserGender, setSelectedUserGender] = useState('')
    const [selecteduserCpf, setSelectedUserCpf] = useState('')
    const [selectedDDDMoble, setSelectedDDDMoble,] = useState('')
    const [selectedMobilePhone, setSelectedMobilePhone,] = useState('')
    const [selectedDDDPhone, setSelectedDDDPhone] = useState('')
    const [selectedPhone, setSelectedPhone,] = useState('')
    const [selectedAssociate, setSelectedAssociate] = useState('')
    const [registrationCode, setRegistrationCode] = useState('')
    const [requireRegitrationCode, setRequireRegitrationCode] = useState(false)

    function cleanState() {
        //limpa states
        setSelectedTypeAccess('')
        setSelectedUserName('')
        setSelectedUserSubName('')
        setSelectedUserEmail('')
        setSelectedUserCpf('')
        setSelectedDDDMoble('')
        setSelectedMobilePhone('')
        setSelectedBirthDay('')

    }

    useEffect(() => {


        async function getTypeAccess() {

            try {

                const typeAccessData = await api_auth.get(`/api/v1/auth/typeAccess`, {
                    headers: {
                        Authorization: `Bearer ${tokenKey}`
                    }
                })

                if (userData[0].typeAccess_id === 1) {
                    setTypeAccessList(typeAccessData?.data?.data.filter((types) => {
                        if ((types.id !== 4)) {
                            return types
                        }
                    }))
                }
                if (userData[0].typeAccess_id === 2) {
                    setTypeAccessList(typeAccessData?.data?.data.filter((types) => {
                        if ((types.id !== 1 && types.id !== 4)) {
                            return types
                        }
                    }))
                }

                if (userData[0].typeAccess_id === 3) {
                    setTypeAccessList(typeAccessData?.data?.data.filter((types) => {
                        if ((types.id !== 1 && types.id !== 3 && types.id !== 4)) {
                            return types
                        }
                    }))
                }
            } catch (error) {
                if (responseError(error).length > 0) {
                    setLoading(false)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }

        async function getPermissionsGroup() {
            try {
                const permissionsData = await api_auth.get(`/api/v1/auth/permissionGroups`, {
                    headers: {
                        Authorization: `Bearer ${userData[0]?.token}`
                    }
                })
                setPermissionsData(permissionsData.data.data)
            } catch (error) {

                if (responseError(error).length > 0) {
                    setLoading(false)
                    return setShowNotificationModalText(responseError(error))
                } else {
                    setLoading(false)
                    return setShowNotificationModalText('Erro inesperado')
                }
            }
        }

        async function getAssociationsList() {

            const associationsGet = await defaultCallsAuth.getAssociations(userData[0]?.token, 1)
            if (associationsGet.error) {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(associationsGet.data)
                return
            }
            setShowNotificationModalSuccess(true)
            setAssociationsList(associationsGet.data.filter(association => association.id !== 1))
            setLoading(false)
        }

        getTypeAccess()
        getPermissionsGroup()
        getAssociationsList()

    }, [])

    async function handleSubmit() {

        const fields = [
            { "name": 'selectedUserName', "value": selectedUserName, "required": true, "type": 'string' },
            { "name": 'selectedUserSubName', "value": selectedUserSubName, "required": true, "type": 'string' },
            { "name": 'selectedUserEmail', "value": selectedUserEmail, "required": true, "type": 'string' },
            { "name": 'selectedBirthDay', "value": selectedBirthDay, "required": true, "type": 'string' },
            { "name": 'selectedTypeAccesss', "value": selectedTypeAccesss, "required": true, "type": 'object' },
            { "name": 'selectedPermissionGroup', "value": selectedPermissionGroup, "required": selectedTypeAccesss.id === 3 ? true : false, "type": 'string' },
            { "name": 'selectedAssociate', "value": selectedAssociate, "required": true, "type": 'object' },
        ]

        setLoading(true)

        if (validateFields(fields)?.length > 0) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText('Campos obrigatórios não preenchidos, preencha os campos em vermelho')
        }

        if (selectedUserName?.length < 2) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText('Verifique o nome, deve ser nome inteiro')
        }

        if (selectedUserSubName?.length < 2) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText('Verifique o sobrenome, deve ser nome inteiro')
        }

        if (!validateEmail(selectedUserEmail)) {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText('E-mail inválido')
        }
        if (selectedTypeAccesss === '') {
            setLoading(false)
            setShowNotificationModalSuccess(false)
            setShowNotificationModal(true)
            return setShowNotificationModalText('Selecione um tipo de acesso')
        }

        try {

            await api_auth.post('api/v1/auth/users', {
                birthday: selectedBirthDay,
                email: selectedUserEmail,
                firstName: selectedUserName,
                lastName: selectedUserSubName,
                typeAccess_id: parseInt(selectedTypeAccesss.id),
                cell: selectedMobilePhone !== '' ? selectedMobilePhone : undefined,
                cpf: selecteduserCpf !== '' ? removeEspecialChar(selecteduserCpf) : undefined,
                dddcell: selectedDDDMoble !== '' ? selectedDDDMoble : undefined,
                dddphone: selectedDDDPhone !== '' ? selectedDDDPhone : undefined,
                gender: selectedUserGender !== '' ? selectedUserGender.id : undefined,
                associations_id: selectedAssociate.id,
                permissionGroups_id: selectedPermissionGroup !== undefined ? selectedPermissionGroup.id : undefined,
                phone: selectedPhone !== '' ? selectedPhone : undefined,
            }, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            navegate('/users')
            setShowNotificationModal(true)
            setShowNotificationModalText('Cadastro realizado com sucesso!')
            setShowNotificationModalSuccess(true)
        } catch (error) {
            if (responseError(error).length > 0) {
                setLoading(false)
                setShowNotificationModal(true)
                setShowNotificationModalSuccess(false)
                return setShowNotificationModalText(responseError(error))
            } else {
                setLoading(false)
                setShowNotificationModalSuccess(false)
                setShowNotificationModal(true)
                return setShowNotificationModalText('Erro inesperado')
            }
        }

        cleanState()
        setLoading(false)

    }

    const sexTypes = [
        {
            name: 'Masculino',
            id: 'M'
        },
        {
            name: 'Feminino',
            id: 'F'
        },
        {
            name: 'Outros',
            id: 'O'
        }
    ]

    return (
        <Container>
            <TitlePage>
                <Title text={'Novo usuário'} />
            </TitlePage>
            <Body hasFooter={true}>
                {
                    loading ?
                        <div className='flex flex-col items-center justify-center w-full h-full'>
                            <DefaultLoader />
                        </div>
                        :
                        <div className='flex flex-col items-start justify-start w-full'>
                            <form className='flex flex-col items-center sm:grid sm:grid-cols-1 md:grid-cols-2 gap-4'>
                                <label className='w-full sm:w-auto flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>Nome *</a>
                                    <Input charLimit={255} id='selectedUserName' type='text' value={selectedUserName} onChange={(e) => setSelectedUserName(e.target.value)} autoFocus={true}></Input>
                                </label>
                                <label className='w-full sm:w-auto flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>Sobrenome *</a>
                                    <Input charLimit={255} id='selectedUserSubName' type='text' value={selectedUserSubName} onChange={(e) => setSelectedUserSubName(e.target.value)}></Input>
                                </label>
                                <label className='w-full sm:w-auto flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>E-mail *</a>
                                    <Input charLimit={255} id='selectedUserEmail' type='e-mail' value={selectedUserEmail} onChange={(e) => { validateEmail(e.target.value); setSelectedUserEmail(e.target.value) }}></Input>
                                </label>
                                <label className='w-full sm:w-auto flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>Data de nascimento *</a>
                                    <Input id='selectedBirthDay' value={selectedBirthDay} type={'date'} onChange={(e) => setSelectedBirthDay(e.target.value)}></Input>
                                </label>
                                <label className='relative w-full sm:w-auto flex flex-col gap-1 items-start justify-start'>
                                    <a className='text-sm text-inputPlaceholderLight'>Tipo de acesso *</a>
                                    <InputAutoComplete
                                        preSelectedValue={selectedTypeAccesss?.description}
                                        data={typeAccessList}
                                        selectedLabel={'description'}
                                        optionList={['description']}
                                        onChange={selectedTypeAccess => setSelectedTypeAccess(selectedTypeAccess)}
                                        id='selectedTypeAccesss'
                                    />
                                </label>
                                {
                                    selectedTypeAccesss.id === 3 &&
                                    <label className='relative w-full sm:w-auto flex flex-col gap-1 items-start justify-start'>
                                        <a className='text-sm text-inputPlaceholderLight'>Permissão *</a>
                                        <InputAutoComplete
                                            data={permissionsData}
                                            selectedLabel={'name'}
                                            optionList={['name']}
                                            onChange={value => setSelectedPermissionGroup(value)}
                                            id='selectedPermissionGroup'
                                        />
                                    </label>
                                }
                                <label className='relative w-full sm:w-auto flex flex-col gap-1 items-start justify-start'>
                                    <a className='text-sm text-inputPlaceholderLight'>Genêro *</a>
                                    <InputAutoComplete
                                        data={sexTypes}
                                        selectedLabel={'name'}
                                        optionList={['id', 'name']}
                                        placeHolder=' - '
                                        preSelectedValue={selectedUserGender?.name}
                                        onChange={e => setSelectedUserGender(e)}
                                        id='selectedUserGender'
                                    />
                                </label>
                                <label className='w-full sm:w-auto flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>CPF</a>
                                    <Input charLimit={14} id='selecteduserCpf' type='text' value={mCPF(selecteduserCpf)} onChange={(e) => setSelectedUserCpf(e.target.value)}></Input>
                                </label>
                                <div className='flex flex-col sm:flex-row gap-1 sm:gap-3'>
                                    <LabelInput text={'DDD'}>
                                        <Input id='selectedDDDMoble' charLimit={2} width={screenX < 1400 ? '10' : '12'} value={selectedDDDMoble} onChange={(e) => {
                                            setSelectedDDDMoble(justNumber(e.target.value))
                                        }}></Input>
                                    </LabelInput>
                                    <LabelInput text={'Celular'}>
                                        <Input charLimit={9} width={'80'} type={'text'} id='selectedMobilePhone' value={selectedMobilePhone} onChange={(e) => setSelectedMobilePhone(justNumber(e.target.value))}></Input>
                                    </LabelInput>
                                </div>
                                <div className='flex flex-col sm:flex-row gap-1 sm:gap-3'>
                                    <LabelInput text={'DDD'}>
                                        <Input charLimit={2} width={screenX < 1400 ? '10' : '12'} type={'text'} onChange={(e) => setSelectedDDDPhone(e.target.value)} id='selectedDDDPhone' value={selectedDDDPhone}></Input>
                                    </LabelInput>
                                    <LabelInput text={'Telefone'}>
                                        <Input charLimit={9} width={'80'} type={'text'} onChange={(e) => setSelectedPhone(e.target.value)} id='selectedPhone' value={selectedPhone ? maskPhone(selectedPhone) : ''}></Input>
                                    </LabelInput>
                                </div>
                                <label className='w-full sm:w-auto flex flex-col gap-1 items-start justify-center'>
                                    <a className='text-sm text-inputPlaceholderLight'>Tipo acesso/associação *</a>
                                    <InputAutoComplete
                                        data={associationsList}
                                        selectedLabel={'name'}
                                        optionList={['id', 'name']}
                                        placeHolder=' - '
                                        onChange={value => setSelectedAssociate(value)}
                                        id='selectedAssociate'
                                    />
                                </label>
                            </form>
                        </div>
                }
            </Body>
            <Footer>
                <div className='flex flex-row gap-1 w-full h-96 items-center justify-between'>
                    <Button
                        id='cancelUserCreate'
                        approval={false}
                        shadow={true}
                        onClick={() => { navegate('/users'); setShowNotificationModal(false) }}
                    >Voltar
                    </Button>
                    <Button
                        id='approvalUserCreate'
                        approval={true}
                        shadow={true}
                        onClick={() => handleSubmit()}
                    >Cadastrar
                    </Button>

                </div>
            </Footer>
        </Container>
    )
}
