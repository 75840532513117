import { responseError } from "../../../utils/responsesFunctions/error.response"

export class DefaultCallFinancial {

    #api_financial

    constructor(api_financial, token) {
        this.#api_financial = api_financial
    }

    //get costCenter
    async getCostCenter(tokenKey, status = null) {

        try {
            const apiData = await this.#api_financial.get(`/api/v1/financial/costCenter`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    async getCostCenterById(tokenKey, id) {

        try {
            const apiData = await this.#api_financial.get(`/api/v1/financial/costCenter/${id}`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //get productsFiscalTypes
    async getProductsFiscalTypers(tokenKey, status = null) {

        try {
            const apiData = await this.#api_financial.get(`/api/v1/financial/productsFiscalTypes`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    async getProducts(tokenKey, status = null) {

        try {
            const apiData = await this.#api_financial.get(`/api/v1/financial/products`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    async getProductsById(tokenKey, id) {

        try {
            const apiData = await this.#api_financial.get(`/api/v1/financial/products/${id}`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //get clientAndSupplier
    async getClientAndSupplier(tokenKey, status = null) {

        try {
            const apiData = await this.#api_financial.get(`/api/v1/financial/clientsAndSuppliers`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    async getUsersClientsAndSuppliers(tokenKey, filter, page, direction, active) {
        let params = `/api/v1/financial/utils/usersClientsAndSuppliers?direction=${direction ? 'desc' : 'asc'}`

        params = filter?.column ? params.concat(`&orderBy=${filter.column}`) : params
        params = page > 0 ? params.concat(`&page=${page}`) : params
        params = active ? params.concat(`&active=true`) : params.concat(`&active=false`)
        params = filter?.filterObject?.linked_id ? params.concat(`&linked_id=${filter?.filterObject?.linked_id}`) : params
        params = filter?.filterObject?.name ? params.concat(`&name=${filter?.filterObject?.name}`) : params
        params = filter?.filterObject?.isSupplier ? params.concat(`&isSupplier=${filter?.filterObject?.isSupplier === "Sim" ? true : false}`) : params
        params = filter?.filterObject?.isClient ? params.concat(`&isClient=${filter?.filterObject?.isClient === "Sim" ? true : false}`) : params
        params = filter?.filterObject?.personType ? params.concat(`&personType=${filter?.filterObject?.personType}`) : params

        try {
            const usersClientsAndSuppliersData = await this.#api_financial.get(params, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })
            let newData = { data: usersClientsAndSuppliersData.data.data.filter(user => user.id !== -1), totalPages: usersClientsAndSuppliersData.data.totalPages, quantity: usersClientsAndSuppliersData.data.quantity }
            return {
                error: false,
                data: newData
            }
        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    async getUsersClientsAndSuppliersWithoutPagination(tokenKey, active) {

        try {
            const usersClientsAndSuppliersData = await this.#api_financial.get(`/api/v1/financial/utils/usersClientsAndSuppliersWithoutPagination${active ? `?status=${active}` : ''}`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: usersClientsAndSuppliersData?.data?.data
            }
        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //get clientAndSupplierById
    async getClientAndSupplierById(tokenKey, id) {

        try {
            const apiData = await this.#api_financial.get(`/api/v1/financial/clientsAndSuppliers/${id}`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    async getPatrimony(tokenKey, status = null) {

        try {
            const apiData = await this.#api_financial.get(`/api/v1/financial/patrimony`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    async getPatrimonyById(tokenKey, id) {

        try {
            const apiData = await this.#api_financial.get(`/api/v1/financial/patrimony/${id}`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    async getPatrimonyHistory(tokenKey, id) {

        try {
            const apiData = await this.#api_financial.get(`/api/v1/financial/patrimonyHistory/patrimony_id/${id}`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    async getFinancialCategoriesGroups(tokenKey, id = null) {
        try {

            const apiData = await this.#api_financial.get(`/api/v1/financial/categoriesGroups${id ? `/${id}` : ''}`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    async getFinancialDreConfig(tokenKey, id = null) {
        try {

            const apiData = await this.#api_financial.get(`/api/v1/financial/categoriesConfigDre${id ? `/${id}` : ''}`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    async getFinancialCategories(tokenKey, id = null) {
        try {

            const apiData = await this.#api_financial.get(`/api/v1/financial/categories${id ? `/${id}` : ''}`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //get Accounts
    async getAccounts(tokenKey, status = null) {

        try {
            const apiData = await this.#api_financial.get(`/api/v1/financial/accounts`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    //get AccountsById
    async getAccountsById(tokenKey, id) {

        try {
            const apiData = await this.#api_financial.get(`/api/v1/financial/accounts/${id}`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    async getFinancialServices(tokenKey, id = null) {
        try {

            const apiData = await this.#api_financial.get(`/api/v1/financial/services${id ? `/${id}` : ''}`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    async getFinancialListLinks(tokenKey, status = null) {
        try {

            const apiData = await this.#api_financial.get(`/api/v1/financial/utils/listLinks`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    async getFinancialConfigPixQrCode(tokenKey, status = null) {
        try {

            const apiData = await this.#api_financial.get(`/api/v1/financial/configPixQrCode?active=true`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    async getFinancialPaymentMethod(tokenKey) {
        try {

            const apiData = await this.#api_financial.get(`/api/v1/financial/utils/paymentMethod`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    async getFinancialCashFlowPerId(tokenKey, id) {
        try {

            const apiData = await this.#api_financial.get(`/api/v1/financial/cashFlow/${id}`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    async getFinancialBillsPerId(tokenKey, id) {
        try {

            const apiData = await this.#api_financial.get(`/api/v1/financial/bills/${id}`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    // Financial Dashboard
    async getFinancialPaymentsLate(tokenKey) {
        try {

            const apiData = await this.#api_financial.get(`/api/v1/financial/dashboards/paymentsLate`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    async getFinancialReceiveLate(tokenKey) {
        try {

            const apiData = await this.#api_financial.get(`/api/v1/financial/dashboards/receiveLate`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    async getFinancialBillsToPay(tokenKey) {
        try {

            const apiData = await this.#api_financial.get(`/api/v1/financial/dashboards/billsToPay`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    async getFinancialBillsToReceive(tokenKey) {
        try {

            const apiData = await this.#api_financial.get(`/api/v1/financial/dashboards/billsToReceive`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    async getFinancialBillsPaid(tokenKey) {
        try {

            const apiData = await this.#api_financial.get(`/api/v1/financial/dashboards/billsPaid`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    async getFinancialBillsReceived(tokenKey) {
        try {

            const apiData = await this.#api_financial.get(`/api/v1/financial/dashboards/billsReceived`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    async getFinancialQuantityShoppings(tokenKey) {
        try {

            const apiData = await this.#api_financial.get(`/api/v1/financial/dashboards/quantityShoppings`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    async getFinancialQuantitySales(tokenKey) {
        try {

            const apiData = await this.#api_financial.get(`/api/v1/financial/dashboards/quantitySales`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    async getFinancialBalanceAccounts(tokenKey) {
        try {

            const apiData = await this.#api_financial.get(`/api/v1/financial/dashboards/balanceAccounts`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    async getFinancialBillsOnYear(tokenKey, year) {
        try {

            const apiData = await this.#api_financial.get(`/api/v1/financial/dashboards/billsOnYear/${year}`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    async exportBills(tokenKey, filters) {
        try {

            let formatFilter = []
            if (Object.keys(filters)?.length > 0) {
                Object.keys(filters)?.map(filter => {
                    formatFilter.push(`${filter}=${filters[filter]?.toString(tokenKey)?.replace(' ', '%')}`)
                })
            }
            formatFilter = formatFilter.length > 0 ? formatFilter.join('&') : ''

            const apiData = await this.#api_financial.get(`/api/v1/financial/bills/export?${formatFilter}`, {
                responseType: 'blob',
                responseEncoding: 'base64',
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData?.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    async exportCashFlow(tokenKey, filters) {
        try {

            let formatFilter = []
            if (Object.keys(filters)?.length > 0) {
                Object.keys(filters)?.map(filter => {
                    formatFilter.push(`${filter}=${filters[filter]?.toString(tokenKey)?.replace(' ', '%')}`)
                })
            }
            formatFilter = formatFilter.length > 0 ? formatFilter.join('&') : ''

            const apiData = await this.#api_financial.get(`/api/v1/financial/cashFlow/export?${formatFilter}`, {
                responseType: 'blob',
                responseEncoding: 'base64',
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData?.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    // financial reports - per category
    async getFinancialReportsPerCategory(tokenKey, filters) {
        let formatFilter = []
        if (Object.keys(filters)?.length > 0) {
            Object.keys(filters)?.map(filter => {
                formatFilter.push(`${filter}=${filters[filter]?.toString(tokenKey)?.replace(' ', '%')}`)
            })
        }
        formatFilter = formatFilter.length > 0 ? formatFilter.join('&') : ''
        try {

            const apiData = await this.#api_financial.get('/api/v1/financial/reports/incomeOrExpensePerCategory?' + formatFilter, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    // financial reports - per cost center
    async getFinancialReportsPerCostCenter(tokenKey, filters) {
        let formatFilter = []
        if (Object.keys(filters)?.length > 0) {
            Object.keys(filters)?.map(filter => {
                formatFilter.push(`${filter}=${filters[filter]?.toString(tokenKey)?.replace(' ', '%')}`)
            })
        }
        formatFilter = formatFilter.length > 0 ? formatFilter.join('&') : ''
        try {

            const apiData = await this.#api_financial.get('/api/v1/financial/reports/incomeOrExpensePerCostCenter?' + formatFilter, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    // financial reports - cash flow monthly
    async getFinancialReportsCashFlowMonthly(tokenKey, filters) {
        let formatFilter = []
        if (Object.keys(filters)?.length > 0) {
            Object.keys(filters)?.map(filter => {
                formatFilter.push(`${filter}=${filters[filter]?.toString(tokenKey)?.replace(' ', '%')}`)
            })
        }
        formatFilter = formatFilter.length > 0 ? formatFilter.join('&') : ''
        try {

            const apiData = await this.#api_financial.get('/api/v1/financial/reports/incomeOrExpensePerMonth?' + formatFilter, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    // financial reports - margin Linked
    async getFinancialReportsMarginLinkeds(tokenKey, filters) {
        let formatFilter = []
        if (Object.keys(filters)?.length > 0) {
            Object.keys(filters)?.map(filter => {
                formatFilter.push(`${filter}=${filters[filter]?.toString(tokenKey)?.replace(' ', '%')}`)
            })
        }
        formatFilter = formatFilter.length > 0 ? formatFilter.join('&') : ''
        try {

            const apiData = await this.#api_financial.get('/api/v1/financial/reports/marginLinked?' + formatFilter, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    // financial rules
    async getFinancialRulesPerId(tokenKey, id) {
        try {

            const apiData = await this.#api_financial.get(`/api/v1/financial/rules/${id}`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    // financial users
    async getFinancialUsersPerId(tokenKey, id) {
        try {

            const apiData = await this.#api_financial.get(`/api/v1/financial/users/${id}`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    // financial usersBalance
    async getFinancialUsersBalancePerUsersId(tokenKey, users_id) {
        try {

            const apiData = await this.#api_financial.get(`/api/v1/financial/usersBalance/${users_id}`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

    // financial associations
    async getFinancialAssociationPerId(tokenKey, associationId) {
        try {

            const apiData = await this.#api_financial.get(`/api/v1/financial/associations/${associationId}`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }

     // financial agreements
     async getFinancialAgreementPerId(tokenKey, agreements_id) {
        try {

            const apiData = await this.#api_financial.get(`/api/v1/financial/agreements/${agreements_id}`, {
                headers: {
                    Authorization: `Bearer ${tokenKey}`
                }
            })

            return {
                error: false,
                data: apiData.data.data
            }

        } catch (error) {
            if (responseError(error).length > 0) {
                return {
                    error: true,
                    data: (responseError(error))
                }
            } else {
                return {
                    error: true,
                    data: 'Erro inesperado'
                }
            }
        }
    }
}
